import { toArabicWord } from "number-to-arabic-words/dist/index-node.js";
import log from "../images/logo.png";

export function voucherPrint(
  id,
  date,
  sectorName,
  totalAmount,
  cashAmount,
  checkAmount,
  from,
  to,
  note,
  tableData
) {
  let printContents =
    `<!DOCTYPE html>
<html lang="ar">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Receipt</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            background-color: #f5f5f5;
            margin-top: 0;
            padding: 20px;
			font-size: 14px;
        }
        .receipt-container {
            width: 600px;
			height: 700px;
            background-color: #fff;
            padding: 20px;
            margin: 0 auto;
            border: 1px solid #ccc;
        }
        .receipt-header {
            margin-bottom: 20px;
            position: relative;
        }
        .receipt-header h2 {
            text-align: center;
            margin-bottom: 0;
        }
        .header-left {
            float: left;
        }
        .header-right {
            float: right;
        }
        .header-clear {
            clear: both;
        }
        .receipt-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }
        .receipt-table th, .receipt-table td {
            border: 1px solid #000;
            padding: 8px;
        }
        .receipt-table th {
            background-color: #f2f2f2;
        }
        .receipt-total {
            text-align: right;
            font-size: 18px;
            margin-bottom: 20px;
        }
		.receipt-footer {
            text-align: right;
            margin-bottom: 20px;
        }
        .signatures {
            margin-top: 50px;
            width: 50%;
            float: left;
        }
        .center-content {
            text-align: center;
        }
            @page { size: portrait; }
    </style>
</head>
<body>
    <div class="receipt-container">
        <div class="receipt-header">
            <h2>وصل قبض</h2>
            <div class="header-left">
                <p>رقم الوصل: ` +
    id +
    `</p>
            </div>
            <div class="header-right">
                <p>اسم الدائرة: ` +
    sectorName +
    `</p>
                <p>التاريخ: ` +
    date +
    `</p>
            </div>
            <div class="header-clear"></div>
        </div>

        <table class="receipt-table">
            <thead>
                <tr>
                    <th>المبلغ</th>
                    <th>اسم الحساب</th>
                    <th>رقم الدليل</th>
                </tr>
            </thead>
            <tbody>`;
  tableData.forEach((row) => {
    printContents =
      printContents +
      `<tr>
                    <td>` +
      row.TOTAL_AMOUNT +
      `</td>
                    <td>` +
      row.AR_VOUCHER_TYPE +
      `</td>
                    <td>` +
      row.VOUCHER_TYPE_CODE +
      `</td>
                </tr>`;
  });
  printContents =
    printContents +
    `</tbody>
        </table>

        <div class="receipt-total">
            <p>المجموع: ` +
    totalAmount +
    ` دينار</p>
			<p>كتابة: ` +
    toArabicWord(totalAmount) +
    ` دينار</p>
        </div>

        <div class="receipt-footer">
            <p> استلمت المبلغ اعلاه من السيد ` +
    from +
    `</p>
			<p>نقدا: ` +
    cashAmount +
    `</p>
			<p>صكوك: ` +
    checkAmount +
    `</p>	
    <p>التفاصيل: ` +
    note +
    `</p>	
        </div>

        <div class="signatures">
            <div class="center-content">
                <p>المستلم</p>
                <p>` +
    to +
    `</p>
            </div>
        </div>
    </div>
</body>
</html>`;

  var myWindow = window.open("", "Print Voucher", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  myWindow.print();
}

export function outgoingVoucherPrint(
  id,
  date,
  sectorName,
  totalAmount,
  cashAmount,
  checkAmount,
  from,
  to,
  tableData
) {
  let printContents =
    `<!DOCTYPE html>
<html lang="ar">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Receipt</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            background-color: #f5f5f5;
            margin-top: 0;
            padding: 20px;
			font-size: 14px;
        }
        .receipt-container {
            width: 600px;
			height: 700px;
            background-color: #fff;
            padding: 20px;
            margin: 0 auto;
            border: 1px solid #ccc;
        }
        .receipt-header {
            margin-bottom: 20px;
            position: relative;
        }
        .receipt-header h2 {
            text-align: center;
            margin-bottom: 0;
        }
        .header-left {
            float: left;
        }
        .header-right {
            float: right;
        }
        .header-clear {
            clear: both;
        }
        .receipt-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }
        .receipt-table th, .receipt-table td {
            border: 1px solid #000;
            padding: 8px;
        }
        .receipt-table th {
            background-color: #f2f2f2;
        }
        .receipt-total {
            text-align: right;
            font-size: 18px;
            margin-bottom: 20px;
        }
		.receipt-footer {
            text-align: right;
            margin-bottom: 20px;
        }
        .signatures {
            margin-top: 50px;
            width: 50%;
            float: left;
        }
        .center-content {
            text-align: center;
        }
    </style>
</head>
<body>
    <div class="receipt-container">
        <div class="receipt-header">
            <h2>سند اخراج</h2>
            <div class="header-left">
                <p>رقم سند الاخراج: ` +
    id +
    `</p>
            </div>
            <div class="header-right">
                <p>اسم الدائرة: ` +
    sectorName +
    `</p>
                <p>التاريخ: ` +
    date +
    `</p>
            </div>
            <div class="header-clear"></div>
        </div>

        <table class="receipt-table">
            <thead>
                <tr>
                    <th>المبلغ</th>
                    <th>اسم الحساب</th>
                    <th>رقم الدليل</th>
                </tr>
            </thead>
            <tbody>`;
  tableData.forEach((row) => {
    printContents =
      printContents +
      `<tr>
                    <td>` +
      row.TOTAL_AMOUNT +
      `</td>
                    <td>` +
      row.AR_VOUCHER_TYPE +
      `</td>
                    <td>` +
      row.VOUCHER_TYPE_CODE +
      `</td>
                </tr>`;
  });
  printContents =
    printContents +
    `</tbody>
        </table>

        <div class="receipt-total">
            <p>المجموع: ` +
    totalAmount +
    ` دينار</p>
			<p>كتابة: ` +
    toArabicWord(totalAmount) +
    ` دينار</p>
        </div>

        <div class="receipt-footer">
            <p> استلمت المبلغ اعلاه من السيد ` +
    from +
    `</p>
			<p>نقدا: ` +
    cashAmount +
    `</p>
			<p>صكوك: ` +
    checkAmount +
    `</p>	
        </div>

        <div class="signatures">
            <div class="center-content">
                <p>المستلم</p>
                <p>` +
    to +
    `</p>
            </div>
        </div>
    </div>
</body>
</html>`;

  var myWindow = window.open("", "Print Voucher", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  myWindow.print();
}

export function mainVoucherBankPrint(
  id,
  date,
  sectorName,
  totalAmount,
  cashAmount,
  checkAmount,
  from,
  to
) {
  let printContents =
    `<!DOCTYPE html>
<html lang="ar">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Receipt</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            background-color: #f5f5f5;
            margin-top: 0;
            padding: 20px;
			font-size: 14px;
        }
        .receipt-container {
            width: 600px;
			height: 700px;
            background-color: #fff;
            padding: 20px;
            margin: 0 auto;
            border: 1px solid #ccc;
        }
        .receipt-header {
            margin-bottom: 20px;
            position: relative;
        }
        .receipt-header h2 {
            text-align: center;
            margin-bottom: 0;
        }
        .header-left {
            float: left;
        }
        .header-right {
            float: right;
        }
        .header-clear {
            clear: both;
        }
        .receipt-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }
        .receipt-table th, .receipt-table td {
            border: 1px solid #000;
            padding: 8px;
        }
        .receipt-table th {
            background-color: #f2f2f2;
        }
        .receipt-total {
            text-align: right;
            font-size: 18px;
            margin-bottom: 20px;
        }
		.receipt-footer {
            text-align: right;
            margin-bottom: 20px;
        }
        .signatures {
            margin-top: 50px;
            width: 50%;
            float: left;
        }
        .center-content {
            text-align: center;
        }
    </style>
</head>
<body>
    <div class="receipt-container">
        <div class="receipt-header">
            <h2>سند اخراج مصرف</h2>
            <div class="header-left">
                <p>رقم سند الاخراج: ` +
    id +
    `</p>
            </div>
            <div class="header-right">
                <p>اسم الدائرة: ` +
    sectorName +
    `</p>
                <p>التاريخ: ` +
    date +
    `</p>
            </div>
            <div class="header-clear"></div>
        </div>

        <div class="receipt-total">
            <p>المجموع: ` +
    totalAmount +
    ` دينار</p>
			<p>كتابة: ` +
    toArabicWord(totalAmount) +
    ` دينار</p>
        </div>

        <div class="receipt-footer">
            <p> استلمت المبلغ اعلاه من السيد ` +
    from +
    `</p>
			<p>نقدا: ` +
    cashAmount +
    `</p>
			<p>صكوك: ` +
    checkAmount +
    `</p>	
        </div>

        <div class="signatures">
            <div class="center-content">
                <p>المستلم</p>
                <p>` +
    to +
    `</p>
            </div>
        </div>
    </div>
</body>
</html>`;

  var myWindow = window.open("", "Print Voucher", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  myWindow.print();
}

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function billsExport(data, dateFrom, dateTo) {
  let listInst = "";
  for (var i = 0; i < data.length; i++) {
    listInst =
      listInst +
      `<tr>
      <td>` +
      data[i].ID +
      `</td>
    <td>` +
      data[i].AMOUNT +
      `</td>
    <td>` +
      data[i].DUE_DATE +
      `</td>
      <td>` +
      data[i].AR_SERVICE_NAME +
      `</td>
      <td>` +
      data[i].FULL_NAME +
      `</td>
      <td>` +
      data[i].TRADE_NAME +
      `</td>
      <td>` +
      data[i].AR_SECTOR_NAME +
      `</td>
      <td>` +
      data[i].AR_ZONE_NAME +
      `</td>
      <td>` +
      (data[i].IS_PAID === "Y" ? "مسددة" : "غير مسددة") +
      `</td>
</tr>`;
  }
  let printContents =
    `<header><title>الفواتير</title><style>
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  @page { size: portrait; }
  </style></header>
  <div id="toPrint" dir="rtl">
  <center>
  <img src="` +
    log +
    `" height="150" width="100" alt="System Logo" />
    <p><br/>
    <b>الفواتير</b>
    <br/><br/>
    </p>
<table style="width:100%;border:none;">
  <tr style="border:none;"> 
    <td style="border:none;">من: ` +
    dateFrom +
    `</td><td style="border:none;">الى: ` +
    dateTo +
    `</td>
    <td style="border:none;"></td>
  </tr>
</table>
<br/>
<table style="width:100%;text-align: center;">
  <tr>
    <th>رقم الفاتورة</th>
    <th>مبلغ الفاتورة</th>
    <th>تاريخ الاستحقاق</th>
    <th>الخدمة</th>
    <th>اسم المشترك</th>
    <th>الاسم التجاري</th>
    <th>القطاع</th>
    <th>المنطقة</th>
    <th>حالة التسديد</th>
  </tr>` +
    listInst +
    `</table>
    <br/>
  </center>
</div>`;

  var myWindow = window.open("", "ُExport", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  sleep(100).then(() => {
    myWindow.print();
  });
}

export function paymentsExport(data, dateFrom, dateTo) {
  let listInst = "";
  for (var i = 0; i < data.length; i++) {
    listInst =
      listInst +
      `<tr>
      <td>` +
      data[i].ID +
      `</td>
    <td>` +
      data[i].AMOUNT +
      `</td>
    <td>` +
      data[i].INSERT_DATE +
      `</td>
      <td>` +
      data[i].FULL_NAME +
      `</td>
      <td>` +
      data[i].TRADE_NAME +
      `</td>
      <td>` +
      data[i].COLLECTOR_NAME +
      `</td>
      <td>` +
      data[i].AR_SECTOR_NAME +
      `</td>
      <td>` +
      data[i].AR_ZONE_NAME +
      `</td>
      <td>` +
      (data[i].IS_SETTELED === "Y" ? "نعم" : "لا") +
      `</td>
</tr>`;
  }
  let printContents =
    `<header><title>التسديدات</title><style>
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  @page { size: landscape; }
  </style></header>
  <div id="toPrint" dir="rtl">
  <center>
  <img src="` +
    log +
    `" height="150" width="100" alt="System Logo" />
    <p><br/>
    <b>التسديدات</b>
    <br/><br/>
    </p>
<table style="width:100%;border:none;">
  <tr style="border:none;"> 
    <td style="border:none;">من: ` +
    dateFrom +
    `</td><td style="border:none;">الى: ` +
    dateTo +
    `</td>
    <td style="border:none;"></td>
  </tr>
</table>
<br/>
<table style="width:100%;text-align: center;">
  <tr>
    <th>رقم الوصل</th>
    <th>المبلغ</th>
    <th>تاريخ الادخال</th>
    <th>اسم المشترك</th>
    <th>الاسم التجاري</th>
    <th>اسم الجابي</th>
    <th>القطاع</th>
    <th>المنطقة</th>
    <th>تم التسوية</th>
  </tr>` +
    listInst +
    `</table>
    <br/>
  </center>
</div>`;

  var myWindow = window.open("", "ُExport", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  sleep(100).then(() => {
    myWindow.print();
  });
}

export function followUpExport(data, dateFrom, dateTo) {
  let listInst = "";
  for (var i = 0; i < data.length; i++) {
    listInst =
      listInst +
      `<tr>
      <td>` +
      data[i].VALUE +
      `</td>
    <td>` +
      data[i].FULL_NAME +
      `</td>
    <td>` +
      data[i].TRADE_NAME +
      `</td>
      <td>` +
      data[i].COLLECTOR_NAME +
      `</td>
      <td>` +
      data[i].AR_ZONE_NAME +
      `</td>
      <td>` +
      data[i].AR_SECTOR_NAME +
      `</td>
      <td>` +
      data[i].INSERT_DATE +
      `</td>
</tr>`;
  }
  let printContents =
    `<header><title>متابعة التحصيل</title><style>
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  @page { size: landscape; }
  </style></header>
  <div id="toPrint" dir="rtl">
  <center>
  <img src="` +
    log +
    `" height="150" width="100" alt="System Logo" />
    <p><br/>
    <b>متابعة التحصيل</b>
    <br/><br/>
    </p>
<table style="width:100%;border:none;">
  <tr style="border:none;"> 
    <td style="border:none;">من: ` +
    dateFrom +
    `</td><td style="border:none;">الى: ` +
    dateTo +
    `</td>
    <td style="border:none;"></td>
  </tr>
</table>
<br/>
<table style="width:100%;text-align: center;">
  <tr>
    <th>القيمة</th>
    <th>اسم المشترك</th>
    <th>الاسم التجاري</th>
    <th>اسم الجابي</th>
    <th>المنطقة</th>
    <th>القطاع</th>
    <th>تاريخ الادخال</th>
  </tr>` +
    listInst +
    `</table>
    <br/>
  </center>
</div>`;

  var myWindow = window.open("", "ُExport", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  sleep(100).then(() => {
    myWindow.print();
  });
}

export function statementExport(data, dateFrom, dateTo) {
  let listInst = "";
  for (var i = 0; i < data.length; i++) {
    listInst =
      listInst +
      `<tr>
      <td>` +
      data[i].TYPE +
      `</td>
    <td>` +
      data[i].PAID_AMOUNT +
      `</td>
    <td>` +
      data[i].UNPAID_AMOUNT +
      `</td>
</tr>`;
  }
  let printContents =
    `<header><title>موقف الجباية</title><style>
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  @page { size: landscape; }
  </style></header>
  <div id="toPrint" dir="rtl">
  <center>
  <img src="` +
    log +
    `" height="150" width="100" alt="System Logo" />
    <p><br/>
    <b>موقف الجباية</b>
    <br/><br/>
    </p>
<table style="width:100%;border:none;">
  <tr style="border:none;"> 
    <td style="border:none;">من: ` +
    dateFrom +
    `</td><td style="border:none;">الى: ` +
    dateTo +
    `</td>
    <td style="border:none;"></td>
  </tr>
</table>
<br/>
<table style="width:100%;text-align: center;">
  <tr>
    <th>النوع</th>
    <th>المبالغ المدفوعة</th>
    <th>المبالغ الغير مدفوعة</th>
  </tr>` +
    listInst +
    `</table>
    <br/>
  </center>
</div>`;

  var myWindow = window.open("", "ُExport", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  sleep(100).then(() => {
    myWindow.print();
  });
}

export function subscriberDueAmountExport(data, dateFrom, dateTo) {
  let listInst = "";
  for (var i = 0; i < data.length; i++) {
    listInst =
      listInst +
      `<tr>
    <td>` +
      data[i].FULL_NAME +
      `</td>
      <td>` +
      data[i].TRADE_NAME +
      `</td>      
      <td>` +
      data[i].UNPAID_AMOUNTS +
      `</td>
      <td>` +
      data[i].PHONE_NO +
      `</td>
      <td>` +
      (data[i].IS_ACTIVE === "Y" ? "فعال" : " غير فعال") +
      `</td>
      <td>` +
      data[i].AR_ZONE_NAME +
      `</td>
      <td>` +
      data[i].AR_SECTOR_NAME +
      `</td>
      <td>` +
      data[i].INSERT_DATE +
      `</td>
</tr>`;
  }
  let printContents =
    `<header><title>مبالغ المشتركين</title><style>
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  @page { size: landscape; }
  </style></header>
  <div id="toPrint" dir="rtl">
  <center>
  <img src="` +
    log +
    `" height="150" width="100" alt="System Logo" />
    <p><br/>
    <b>متابعة التحصيل</b>
    <br/><br/>
    </p>
<br/>
<table style="width:100%;text-align: center;">
  <tr>
    <th>اسم المشترك</th>
    <th>الاسم التجاري</th>
    <th>المبلغ المطلوب</th>
    <th>رقم الهاتف</th>
    <th>الحالة</th>
    <th>المنطقة</th>
    <th>القطاع</th>
    <th>تاريخ الادخال</th>    
  </tr>` +
    listInst +
    `</table>
    <br/>
  </center>
</div>`;

  var myWindow = window.open("", "ُExport", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  sleep(100).then(() => {
    myWindow.print();
  });
}

export function collectorDueAmountExport(data) {
  let listInst = "";
  for (var i = 0; i < data.length; i++) {
    listInst =
      listInst +
      `<tr>
    <td>` +
      data[i].FULL_NAME +
      `</td>
      <td>` +
      data[i].DUE_AMOUNT +
      `</td>
      <td>` +
      data[i].PHONE_NO +
      `</td>
      <td>` +
      data[i].AR_SECTOR_NAME +
      `</td>
      <td>` +
      (data[i].IS_BLOCKED === "Y" ? "نعم" : " كلا") +
      `</td>
      <td>` +
      data[i].INSERT_DATE +
      `</td>
</tr>`;
  }
  let printContents =
    `<header><title>مبالغ الجباة</title><style>
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  @page { size: landscape; }
  </style></header>
  <div id="toPrint" dir="rtl">
  <center>
  <img src="` +
    log +
    `" height="150" width="100" alt="System Logo" />
    <p><br/>
    <b>متابعة التحصيل</b>
    <br/><br/>
    </p>
<table style="width:100%;border:none;">
  </tr>
</table>
<br/>
<table style="width:100%;text-align: center;">
  <tr>
    <th>اسم الجابي</th>
    <th>المبلغ المطلوب</th>
    <th>رقم الهاتف</th>
    <th>محظور</th>
    <th>القطاع</th>
    <th>تاريخ الادخال</th>    
  </tr>` +
    listInst +
    `</table>
    <br/>
  </center>
</div>`;

  var myWindow = window.open("", "ُExport", "");
  myWindow.document.write(printContents);
  myWindow.document.close();
  myWindow.focus();
  sleep(100).then(() => {
    myWindow.print();
  });
}
