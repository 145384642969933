import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./home";
import ChangePassword from "./changePassword";
import Notfound from "./notfound";
import ApplicationInfo from "../mainComponents/applicationInfo";
import UserManagement from "../contentComponents/system/usermanagement";
import RoleManagement from "../contentComponents/system/rolemanagement";
import SystemParameters from "../contentComponents/system/systemparameters";
import sysJwt from "../../utils/sysJwt";
import Reports from "../contentComponents/reports/reports";
import ExecuteReport from "../contentComponents/reports/executeReport";
import Sectors from "../contentComponents/definitions/sector";
import Zones from "../contentComponents/definitions/zone";
import Collectors from "../contentComponents/definitions/collector";
import Subscribers from "../contentComponents/definitions/subscriber";
import SubscriberRequests from "../contentComponents/definitions/subscriberRequest";
import SubscribeCategories from "../contentComponents/definitions/subscriberCategory";
import VoucherTypes from "../contentComponents/definitions/voucherType";
import MunicipalityProperty from "../contentComponents/definitions/municipalityProperty";
import Payments from "../contentComponents/payments/payment";
import Bills from "../contentComponents/bills/bill";
import Contracts from "../contentComponents/contract/contract";
import Vouchers from "../contentComponents/vouchers/voucher";
import MainVouchers from "../contentComponents/vouchers/mainVoucher";
import OutgoingVouchers from "../contentComponents/vouchers/OutgoingVoucher";
import MainVoucherBank from "../contentComponents/vouchers/mainVoucherBank";
import Statement from "../contentComponents/statement/statement";
import FollowUp from "../contentComponents/statement/followUp";
import SubscriberDueAmount from "../contentComponents/statement/subscriberDueAmount";
import CollectorDueAmount from "../contentComponents/statement/collectorDueAmount";

class AppRoutes extends Component {
  state = {};
  render() {
    return (
      <Routes>
        {sysJwt.isAuthorized(["CAEC"]) && (
          <Route
            path="/reports"
            element={
              <Reports
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAEC", "CAF0"]) && (
          <Route
            path="/executereport"
            element={
              <ExecuteReport
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CABE"]) && (
          <Route
            path="/usermanagement"
            element={
              <UserManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAC5"]) && (
          <Route
            path="/rolemanagement"
            element={
              <RoleManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAD5"]) && (
          <Route
            path="/systemparameters"
            element={
              <SystemParameters
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF3"]) && (
          <Route
            path="/sectors"
            element={
              <Sectors
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF7"]) && (
          <Route
            path="/zones"
            element={
              <Zones
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAFF"]) && (
          <Route
            path="/collectors"
            element={
              <Collectors
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB06"]) && (
          <Route
            path="/subscribers"
            element={
              <Subscribers
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0A"]) && (
          <Route
            path="/subscriberrequests"
            element={
              <SubscriberRequests
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAFB"]) && (
          <Route
            path="/categories"
            element={
              <SubscribeCategories
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB18"]) && (
          <Route
            path="/vouchertypes"
            element={
              <VoucherTypes
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB1C"]) && (
          <Route
            path="/municipalityproperty"
            element={
              <MunicipalityProperty
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0E"]) && (
          <Route
            path="/payments"
            element={
              <Payments
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0D"]) && (
          <Route
            path="/bills"
            element={
              <Bills
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB20"]) && (
          <Route
            path="/contracts"
            element={
              <Contracts
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0F"]) && (
          <Route
            path="/vouchers"
            element={
              <Vouchers
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB11"]) && (
          <Route
            path="/outgoingvouchers"
            element={
              <OutgoingVouchers
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB13"]) && (
          <Route
            path="/mainvouchers"
            element={
              <MainVouchers
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB15"]) && (
          <Route
            path="/mainvouchersBank"
            element={
              <MainVoucherBank
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB17"]) && (
          <Route
            path="/statement"
            element={
              <Statement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB25"]) && (
          <Route
            path="/followup"
            element={
              <FollowUp
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB28"]) && (
          <Route
            path="/subscriberdueamounts"
            element={
              <SubscriberDueAmount
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB29"]) && (
          <Route
            path="/collectordueamounts"
            element={
              <CollectorDueAmount
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        <Route
          path="/applicationinfo"
          element={
            <ApplicationInfo
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route
          path="/changepassword"
          element={
            <ChangePassword
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route
          path="/home"
          element={<Home updateIsLoading={this.props.updateIsLoading} />}
        />
        <Route path="/notfound" element={<Notfound />} />
        <Route
          path="*"
          element={<Navigate to="/dashboard/notfound" replace />}
        />
      </Routes>
    );
  }
}

export default AppRoutes;
