import React from "react";
import { Button } from "primereact/button";

const Notfound = (props) => {
  return (
    <div className="error">
      <div className="container-floud">
        <div className="col-xs-12 ground-color text-center">
          <div className="container-error-404">
            <div className="clip">
              <div className="shadow">
                <span className="digit thirdDigit"> 4 </span>
              </div>
            </div>
            <div className="clip">
              <div className="shadow">
                <span className="digit secondDigit"> 0 </span>
              </div>
            </div>
            <div className="clip">
              <div className="shadow">
                <span className="digit firstDigit"> 4 </span>
              </div>
            </div>
            <div className="msg">
              OH!
              <span className="triangle" />
            </div>
          </div>
          <h2 className="h1">
            The page you are trying to access does not exist
          </h2>
          <Button
            label="Back"
            icon="fa fa-home"
            className="p-button-rounded p-button-primary"
            onClick={() => window.location.replace("/dashboard/home")}
          />
        </div>
      </div>
    </div>
  );
};

export default Notfound;
