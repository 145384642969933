export function escapeRegExp(str) {
  return str.replace(/([.*+?^=!${}()|[\]/\\])/g, "\\$1");
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

export function customFilter(filter, row) {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null) {
    return row[id] !== undefined
      ? String(String(row[id]).toLowerCase()).includes(
          filter.value.toLowerCase()
        )
      : true;
  }
}

export function getSysDate(date) {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
}

export function getSysDate2(date) {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
}
export function getSysDate3(date) {
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  );
}

export function getTimeStamp(date = new Date()) {
  var timestamp = "";

  timestamp += date.getFullYear();
  timestamp += date.getMonth() + 1;
  timestamp += date.getDate();
  timestamp += date.getHours();
  timestamp += date.getMinutes();
  timestamp += date.getSeconds();

  return timestamp;
}

export function getTime(date) {
  var time = "";
  time = date.getHours() + ":" + date.getMinutes();
  return time;
}

export function isNullOrWhiteSpace(stringInput) {
  return !stringInput || !stringInput.trim();
}

export function empty(e) {
  switch (e) {
    case "":
    case null:
    case false:
    case undefined:
      return true;
    default:
      return false;
  }
}
