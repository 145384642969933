import React, { Component } from "react";
import homelogo from "../../images/homeLoad.png";
import SysForm from "../commonComponents/sysForm";
import { post } from "../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

class ChangePassword extends Component {
  state = {
    data: {
      CurrentPassword: "",
      NewPassword: "",
      PasswordConfirmation: "",
    },

    inModalMode: true,
    modalHeader: "تغيير كلمة المرور",
    modalSubmitButtonLabel: "تغيير",
    modalSubmitButtonIcon: "fa fa-lock",
    modalWidth: "25vw",
  };

  componentDidMount() {
    this.setState({ inModalMode: this.props.inModalMode });
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({ inModalMode: false, data: data });

    window.location.replace("/dashboard/home");
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  doSubmit() {
    post(
      "/api/account/changepassword",
      this.state.data,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(true);
        this.sleep(4000).then(() => window.location.replace("/login"));
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-logo text-center">
          <img src={homelogo} width={1000} height={500} alt="HOME" />
        </div>
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeader}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabel}
                icon={this.state.modalSubmitButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "password",
                id: "CurrentPassword",
                label: "كلمة المرور الحالية",
              },
              {
                type: "password",
                id: "PasswordConfirmation",
                label: "تاكييد كلمة المرور الجديدة",
              },
              {
                type: "password",
                id: "NewPassword",
                label: "كلمة المرور الجديدة",
                tooltip:
                  "The new password must not be less than 8 letters or numbers, contain at least one uppercase letter, one lowercase letter and one number",
              },
            ]}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
