/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";

class SubscriberRequestRequests extends Component {
  state = {
    data: {
      fullName: "",
      tradeName: "",
      fullAddress: "",
      phoneNo: "",
      siteArea: "",
      signSize: "",
      signLang: "",
      latitude: "",
      longtude: "",
      zoneId: "",
      subscriberCategoryId: "",
      collectorId: "",
    },

    rejectData: {
      rejectReason: "",
    },

    rejectSubscriberRequestId: 0,

    contentHeader: "طلبات المشتركين",
    addButtonLabel: "اضافة طلب مشترك",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetSubscriberRequests",
    singleItemUrl: "/api/data/GetSubscriberRequest",
    addUrl: "/api/data/AddSubscriberRequest",
    editUrl: "/api/data/EditSubscriberRequest",
    deleteUrl: "/api/data/DeleteSubscriberRequest",

    inEditMode: false,
    inModalMode: false,
    inRejectMode: false,
    inImageMode: false,
    modalHeaderAdd: "اضافة طلب مشترك",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل طلب مشترك",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",
    imageModalWidth: "70vw",

    editEntityId: 0,

    zonesDropdown: [],
    categoriesDropdown: [],
    subscriberImages: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="عرض الصور">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => this.getSubscriberImages(data)}
                >
                  <i className="fa fa-eye" />
                </button>
              </div>
              {/* {sysJwt.isAuthorized(["CAC0"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )} */}
              {/* {sysJwt.isAuthorized(["CAC1"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )} */}
              {sysJwt.isAuthorized(["CB0B"]) &&
                data.STATUS_ID_FK !== 4 &&
                data.STATUS_ID_FK !== 2 && (
                  <div className="inner" title="قبول">
                    <button
                      className="dropdown-item btn-popup-menu"
                      disabled={this.props.isLoading}
                      onClick={() => this.handleApprove(data)}
                    >
                      <i className="fa fa-check" />
                    </button>
                  </div>
                )}
              {sysJwt.isAuthorized(["CB0C"]) &&
                data.STATUS_ID_FK !== 4 &&
                data.STATUS_ID_FK !== 2 && (
                  <div className="inner" title="رفض">
                    <button
                      className="dropdown-item btn-popup-menu"
                      disabled={this.props.isLoading}
                      onClick={() => this.handleReject(data)}
                    >
                      <i className="fa fa-ban" />
                    </button>
                  </div>
                )}
            </div>
          );
        },
      },
      {
        id: 2,
        label: "ID",
        field: "ID",
        width: "100px",
      },
      {
        id: 3,
        label: "نوع الطلب",
        field: "REQUEST_TYPE",
        width: "110px",
      },
      {
        id: 4,
        label: "حالة الطلب",
        field: "AR_STATUS_NAME",
        width: "140px",
      },
      {
        id: 5,
        label: "اسم المشترك",
        field: "FULL_NAME",
      },
      {
        id: 6,
        label: "الاسم التجاري",
        field: "TRADE_NAME",
      },
      {
        id: 7,
        label: "نوع النشاط",
        field: "AR_CATEGORY_NAME",
      },
      {
        id: 8,
        label: "الجابي",
        field: "FULL_COLLECTOR_NAME",
      },
      {
        id: 9,
        label: "رقم الهاتف",
        field: "PHONE_NO",
      },
      {
        id: 10,
        label: "المنطقة",
        field: "AR_ZONE_NAME",
      },
      {
        id: 11,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },
      {
        id: 12,
        label: "العنوان الكامل",
        field: "FULL_ADDRESS",
      },
      {
        id: 13,
        label: "مساحة المكان",
        field: "SITE_AREA",
      },
      {
        id: 14,
        label: "عدد الابواب",
        field: "NO_OF_DOORS",
      },
      {
        id: 15,
        label: "مساحة لوحة الاعلان",
        field: "SIGN_SIZE",
      },
      {
        id: 16,
        label: "لغة الاعلان",
        field: "AR_SIGN_TYPE",
      },
      {
        id: 17,
        label: "Latitude",
        field: "LATITUDE",
      },
      {
        id: 18,
        label: "Longtude",
        field: "LONGTUDE",
      },
      {
        id: 19,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 20,
        label: "تاريخ التعديل",
        field: "LAST_UPDATE",
      },
    ],
  };

  componentDidMount() {
    this.getData();
    //this.getZonesDropdown();
    //this.getCategoriesDropdown();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  updateRejectForm = (inputId, inputValue) => {
    var rejectData = this.state.rejectData;
    rejectData[inputId] = inputValue;
    this.setState({ rejectData });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = null);
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  closeRejectModal() {
    var rejectData = this.state.rejectData;

    Object.keys(rejectData).forEach(function (key) {
      Array.isArray(rejectData[key])
        ? (rejectData[key] = [])
        : (rejectData[key] = null);
    });

    this.setState({
      rejectData: rejectData,
      rejectSubscriberRequestId: 0,
      inRejectMode: false,
    });

    this.getData();
  }

  closeImageModal() {
    this.setState({
      subscriberImages: [],
      inImageMode: false,
    });
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["fullName"] = apiResponse.data.respData.data[0].FULL_NAME;
      data["tradeName"] = apiResponse.data.respData.data[0].TRADE_NAME;
      data["fullAddress"] = apiResponse.data.respData.data[0].FULL_ADDRESS;
      data["phoneNo"] = apiResponse.data.respData.data[0].PHONE_NO;
      data["siteArea"] = apiResponse.data.respData.data[0].SITE_AREA;
      data["signSize"] = apiResponse.data.respData.data[0].SIGN_SIZE;
      data["signLang"] = apiResponse.data.respData.data[0].SIGN_LANG;
      data["latitude"] = apiResponse.data.respData.data[0].LATITUDE;
      data["longtude"] = apiResponse.data.respData.data[0].LONGTUDE;
      data["zoneId"] = apiResponse.data.respData.data[0].ZONE_ID_FK;
      data["subscriberCategoryId"] =
        apiResponse.data.respData.data[0].SUBSCRIBER_CATEGORY_ID_FK;
      data["collectorId"] = apiResponse.data.respData.data[0].COLLECTOR_ID_FK;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  getSubscriberImages(row) {
    return post(
      "/api/data/GetSubscriberrequestimages",
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        subscriberImages: apiResponse.data.respData.data,
        inImageMode: true,
      });
    });
  }

  getZonesDropdown() {
    post("/api/data/getZonesDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          zonesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getCategoriesDropdown() {
    post(
      "/api/data/GetSubscriberCategoriesDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        categoriesDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف الطلب مشترك",
      message: "هل انت متأكد من حذف هذه الطلب مشترك؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  handleApprove(row) {
    confirmAlert({
      title: "الموافقة على طلب مشترك",
      message: "هل انت متأكد من الموافقة على طلب مشترك؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              "/api/data/ApproveSubscriberRequest",
              { SubscriberRequestId: row.ID },
              this.props.updateIsLoading
            ).then(() => this.getData());
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  handleReject(row) {
    this.setState({ inRejectMode: true, rejectSubscriberRequestId: row.ID });
  }

  doReject() {
    const rejectData = Object.assign({}, this.state.rejectData);
    rejectData["SubscriberRequestId"] = this.state.rejectSubscriberRequestId;
    post(
      "/api/data/RejectSubscriberRequest",
      rejectData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeRejectModal();
      }
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left"></div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "fullName",
                label: "الاسم الكامل",
                disabled: false,
              },
              {
                type: "text",
                id: "tradeName",
                label: "الاسم التجاري",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "subscriberCategoryId",
                label: "نوع النشاط",
                options: this.state.categoriesDropdown,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "zoneId",
                label: "المنطقة",
                options: this.state.zonesDropdown,
                disabled: false,
              },
              {
                type: "text",
                id: "fullAddress",
                label: "العنوان الكامل",
                disabled: false,
              },
              {
                type: "text",
                id: "phoneNo",
                label: "رقم الهاتف",
                disabled: false,
              },
              {
                type: "text",
                id: "siteArea",
                label: "مساحة المكان",
                disabled: this.state.inEditMode,
              },
              {
                type: "text",
                id: "signSize",
                label: "مساحة الاعلان",
                disabled: this.state.inEditMode,
              },
              {
                type: "text",
                id: "signLang",
                label: "لغة الاعلان",
                disabled: this.state.inEditMode,
              },
              {
                type: "text",
                id: "latitude",
                label: "Latitude",
                disabled: this.state.inEditMode,
              },
              {
                type: "text",
                id: "longtude",
                label: "Longtude",
                disabled: this.state.inEditMode,
              },
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inImageMode}
          style={{ width: this.state.imageModalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeImageModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{"الصور"}</h5>}
          footer={
            <div>
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeImageModal()}
              />
            </div>
          }
        >
          <div>
            {this.state.subscriberImages.map((image) => (
              <img
                key={image.ID}
                src={image.PATH + "?token=" + sessionStorage.getItem("748784")}
                style={{ marginBottom: "80px" }}
              />
            ))}
          </div>
        </Dialog>
        <Dialog
          visible={this.state.inRejectMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeRejectModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{"رفض اضافة مشترك"}</h5>}
          footer={
            <div>
              <Button
                label={"رفض"}
                icon={"fa fa-delete"}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doReject()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeRejectModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.rejectData}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "rejectReason",
                label: "سبب الرفض",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default SubscriberRequestRequests;
