import React, { Component } from "react";
import Axios from "axios";
import { ThreeCircles } from "react-loader-spinner";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { toastError } from "../../utils/sysToast.js";
import { ToastContainer, Flip } from "react-toastify";
import logo from "../../images/logo.png";

class LoginForm extends Component {
  state = {
    Username: "",
    Password: "",
    isLoading: false,
    errDesc: "",
  };

  componentDidMount() {
    this.setState({ Username: localStorage.getItem("lastUsernameSjc") });
    document
      .getElementById("username")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          document.getElementById("btnLogin").click();
        }
      });
    document
      .getElementById("password")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          document.getElementById("btnLogin").click();
        }
      });
  }

  handleLogin() {
    this.setState({ isLoading: true });
    if (!this.state.Username || !this.state.Password) {
      this.setState({ errDesc: "ادخل كلمة اسم المستخدم وكلمة المرور" });
      this.setState({ isLoading: false });
    } else {
      Axios.post("/api/account/dashboardlogin", {
        username: this.state.Username,
        password: this.state.Password,
      })
        .then((apiResponse) => {
          if (apiResponse.data.respCode === 0) {
            localStorage.setItem("lastUsernameSjc", this.state.Username);
            sessionStorage.setItem("748784", apiResponse.data.respData.jWT);
            window.location.replace("/dashboard/home");
          } else {
            this.setState({ isLoading: false });
            this.setState({ errDesc: apiResponse.data.respDesc });
            //toastError(apiResponse.data.respDesc);
          }
        })
        .catch((apiResponse) => {
          this.setState({ isLoading: false });
          this.setState({ errDesc: apiResponse.response.statusText });
        });
    }
  }
  render() {
    return (
      <div className="main">
        <div className="container">
          <center>
            <div className="">
              <div
                id="login"
                style={{ backgroundColor: "white", borderRadius: "15px" }}
              >
                <img src={logo} height="200" width="150" alt="System Logo" />
                <br />
                <label className="eer-label">{this.state.errDesc}</label>

                <span
                  className="p-float-label"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <span>
                    <i className="fa fa-user" />
                    اسم المستخدم
                  </span>
                  <InputText
                    id="username"
                    value={this.state.Username}
                    className="login-input"
                    onChange={(e) =>
                      this.setState({ Username: e.target.value })
                    }
                  />
                </span>
                <span
                  className="p-float-label"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <span>
                    <i className="fa fa-lock" />
                    كلمة المرور
                  </span>
                  <InputText
                    id="password"
                    value={this.state.Password}
                    className="login-input"
                    type="password"
                    onChange={(e) =>
                      this.setState({ Password: e.target.value })
                    }
                  />
                </span>
                <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                  <Button
                    id="btnLogin"
                    label={
                      <label style={{ "text-align": "center" }}>
                        {this.state.isLoading ? (
                          <ThreeCircles color="white" width="30" height="30" />
                        ) : (
                          "تسجيل دخول"
                        )}
                      </label>
                    }
                    className="p-button-light login-button"
                    style={{
                      "text-align": "center",
                      "background-color": "#607D8B",
                    }}
                    onClick={() => {
                      this.handleLogin();
                    }}
                  />
                </div>
                <div className="clearfix" />
                <div className="clearfix" />
              </div>
            </div>
          </center>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          closeButton={false}
          rtl={false}
          transition={Flip}
          newestOnTop
          closeOnClick
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default LoginForm;
