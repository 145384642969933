/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import Joi from "joi-browser";
import XLSX from "xlsx";
import Popup from "reactjs-popup";
import queryString from "query-string";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { CSVLink } from "react-csv";
import { InputText } from "primereact/inputtext";
import { toastError } from "../../../utils/sysToast";
import { ExportSheet } from "react-xlsx-sheet";
import GridTable from "@nadavshaar/react-grid-table";
import { Calendar } from "primereact/calendar";
import { getSysDate2, getSysDate3 } from "../../../utils/stringHelper";

class ExecuteReport extends Component {
  state = {
    inputs: [],
    data: {},

    inModalMode: false,

    reportData: [],
    reportColumns: [],
    xlsxHeaders: [],

    queryString: queryString.parse(window.location.search),
    isParameterized:
      queryString.parse(window.location.search).ip === undefined ? false : true,
  };

  schema = {};

  constructor(props) {
    super(props);
    if (!this.state.isParameterized) {
      post(
        "/api/data/executereport",
        {
          ReportId: this.state.queryString.id,
          IsParameterized: false,
          ReportParams: "",
        },
        props.updateIsLoading // In constructor
      )
        .then((apiResponse) => {
          this.buildReportTable(apiResponse.data.respData.data);
        })
        .catch(() => {
          window.location.replace("/dashboard/reports"); // Not in constructor
        });
    } else {
      var splittedParams = this.state.queryString.qp.split("&");

      // Removes the first item from the array.
      splittedParams.shift();

      // In constructor.
      splittedParams.forEach((param) => {
        this.state.data[param.toUpperCase()] = "";

        this.state.inputs.push({
          name: param.toUpperCase(),
        });

        this.schema[param.toUpperCase()] = Joi.string()
          .required()
          .label(param.toUpperCase());
      });

      this.state.inModalMode = true;
    }
  }

  closeModal() {
    window.location.replace("/dashboard/reports");
  }

  buildReportTable(reportData) {
    let columns = [];
    let xlsxHeaders = [];
    let co = 1;
    if (reportData.length !== 0) {
      for (var property in reportData[0]) {
        if (reportData[0].hasOwnProperty(property)) {
          columns.push({
            id: co,
            field: property,
            label: property,
          });
          co++;
          xlsxHeaders.push({ title: property, dataIndex: property });
        }
      }
      this.setState({
        reportData: reportData,
        reportColumns: columns,
        xlsxHeaders: xlsxHeaders,
      });
    } else {
      window.location.replace("/dashboard/reports");
    }
  }

  handleExecuteParamReport() {
    const options = { abortEarly: true };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) {
      post(
        "/api/data/executereport",
        {
          ReportId: this.state.queryString.id,
          IsParameterized: true,
          ReportParams: this.state.data,
        },
        this.props.updateIsLoading
      )
        .then((apiResponse) => {
          this.buildReportTable(apiResponse.data.respData.data);
          this.setState({ inModalMode: false });
        })
        .catch(() => {
          window.location.replace("/dashboard/reports");
        });
    } else {
      toastError(error.details[0].message);
    }
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.queryString.rn}</h4>
          </div>
          <div className="col flex-container-left">
            <ExportSheet
              header={this.state.xlsxHeaders}
              dataSource={this.state.reportData}
              fileName={this.state.queryString.rn}
              isRequiredNameDate={false}
              xlsx={XLSX}
            >
              <Button
                label="تصدير اكسل"
                icon="fa fa-file-excel-o"
                className="p-button-success flex-item-left mr-2"
                disabled={this.props.isLoading}
              />
            </ExportSheet>
            &nbsp;&nbsp;
            <Button
              label="رجوع"
              icon="fa fa-arrow-circle-left"
              className="p-button-danger flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                window.location.replace("/dashboard/reports");
              }}
            />
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: "25vw" }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => null}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">متغيرات التقرير</h5>}
          footer={
            <div>
              <Button
                label="تنفيذ"
                icon="fa fa-bolt"
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.handleExecuteParamReport()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          {this.state.inputs.map((i) =>
            i.name.includes("DATE") ? (
              <div className="form-group" key={i.name}>
                <label htmlFor={i.name}>{i.name}</label>
                <Calendar
                  id={i.name}
                  name={i.name}
                  value={new Date(this.state.data[i.name])}
                  className="form-control calendar"
                  type="text"
                  disabled={this.props.isLoading}
                  showIcon="true"
                  dateFormat="yy-mm-dd"
                  onChange={(e) => {
                    var data = this.state.data;
                    data[e.target.id] = getSysDate3(e.target.value);
                    this.setState({ data });
                  }}
                />
              </div>
            ) : (
              <div className="form-group" key={i.name}>
                <label htmlFor={i.name}>{i.name}</label>
                <InputText
                  id={i.name}
                  name={i.name}
                  value={this.state.data[i.name]}
                  className="form-control form-control-sm"
                  type="text"
                  disabled={this.props.isLoading}
                  onChange={(e) => {
                    var data = this.state.data;
                    data[e.currentTarget.id] = e.currentTarget.value;
                    this.setState({ data });
                  }}
                />
              </div>
            )
          )}
        </Dialog>
        {this.state.reportData.length > 0 ? (
          <GridTable
            columns={this.state.reportColumns}
            rows={this.state.reportData}
            pageSizes={[10, 15, 20, 30, 40]}
            showColumnVisibilityManager={false}
            texts={{
              search: "بحث:",
              totalRows: "إجمالي عدد البيانات:",
              rows: "الصف:",
              selected: "المحدد",
              rowsPerPage: "عدد الصفوف في الصفحة:",
              page: "الصفحة:",
              of: "من",
              prev: "السابق",
              next: "التالي",
              columnVisibility: "اظهار الاعمدة",
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default ExecuteReport;
