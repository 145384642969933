/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post } from "../../../utils/sysAxios";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";
import { Calendar } from "primereact/calendar";
import { toastError } from "../../../utils/sysToast";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import { Dropdown } from "primereact/dropdown";
import { getSysDate3, empty } from "../../../utils/stringHelper";
import { ExportSheet } from "react-xlsx-sheet";
import XLSX from "xlsx";
import { statementExport } from "../../../utils/printFroms";

class Statements extends Component {
  state = {
    contentHeader: "موقف الجباية",

    dataUrl: "/api/data/GetStatement",

    sectorsDropdown: [],
    sectorId: "",

    dates: "",

    xlsxHeaders: [],
    xlsxData: [],
    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "النوع",
        field: "TYPE",
        width: "600px",
      },
      {
        id: 2,
        label: "المبالغ المدفوعة",
        field: "PAID_AMOUNT",
      },
      {
        id: 3,
        label: "المبالغ الغير مدفوعة",
        field: "UNPAID_AMOUNT",
      },
    ],
  };

  componentDidMount() {
    this.getSectorsDropdown();
  }

  getData() {
    if (!this.state.dates[0] || !this.state.dates[1]) {
      toastError("يرجى اختيار من تاريخ الى تاريخ");
    } else {
      post(
        this.state.dataUrl,
        {
          dateFrom: this.state.dates[0],
          dateTo: this.state.dates[1],
          sectorId: this.state.sectorId,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        this.setState({
          tableData: apiResponse.data.respData.data,
          xlsxData: apiResponse.data.respData.data,
        });
        this.buildExcelTable(apiResponse.data.respData.data);
      });
    }
  }

  getSectorsDropdown() {
    post("/api/data/getSectorsDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          sectorsDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  buildExcelTable(reportData) {
    let xlsxHeaders = [];
    let co = 1;
    if (reportData.length !== 0) {
      for (var property in reportData[0]) {
        if (reportData[0].hasOwnProperty(property)) {
          co++;
          xlsxHeaders.push({ title: property, dataIndex: property });
        }
      }
      this.setState({
        xlsxHeaders: xlsxHeaders,
      });
    }
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            التاريخ:
            <Calendar
              selectionMode="range"
              showIcon="true"
              value={this.state.dates}
              className="flex-item-left mr-2"
              onChange={(e) => this.setState({ dates: e.value })}
              style={{ width: "250px" }}
            ></Calendar>
            &nbsp; القطاع:
            <Dropdown
              value={this.state.sectorId}
              showClear={true}
              options={this.state.sectorsDropdown}
              filter={true}
              onChange={(e) => {
                this.setState({ sectorId: e.target.value });
              }}
            />
            &nbsp;
            <Button
              label="بحث"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
        <div className="row">
          <div className="col-3">
            <p>
              {" "}
              مجموع المدفوع :{" "}
              {this.state.tableData.length > 0
                ? this.state.tableData
                    .reduce(
                      (s, e) =>
                        s +
                        parseInt(
                          e.PAID_AMOUNT ? e.PAID_AMOUNT.replaceAll(",", "") : 0
                        ),
                      0
                    )
                    .toLocaleString()
                : 0}
            </p>
          </div>
          <div className="col-3">
            <p>
              {" "}
              مجموع الغير مدفوع :{" "}
              {this.state.tableData.length > 0
                ? this.state.tableData
                    .reduce(
                      (s, e) =>
                        s +
                        parseInt(
                          e.UNPAID_AMOUNT
                            ? e.UNPAID_AMOUNT.replaceAll(",", "")
                            : 0
                        ),
                      0
                    )
                    .toLocaleString()
                : 0}
            </p>
          </div>
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <ExportSheet
                header={this.state.xlsxHeaders}
                dataSource={this.state.xlsxData}
                fileName="DataExport"
                isRequiredNameDate={false}
                xlsx={XLSX}
              >
                <Button
                  className="p-button-success"
                  label="تصدير اكسل"
                  icon="fa fa-file-excel-o"
                />
              </ExportSheet>
            ) : (
              ""
            )}
          </div>
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <Button
                className="p-button-secondary"
                label="تصدير PDF"
                icon="fa fa-file-pdf-o"
                onClick={() => {
                  statementExport(
                    this.state.tableData,
                    this.state.dates ? getSysDate3(this.state.dates[0]) : "",
                    this.state.dates ? getSysDate3(this.state.dates[1]) : ""
                  );
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Statements;
