/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";
import { voucherPrint } from "../../../utils/printFroms";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { empty } from "../../../utils/stringHelper";
import { confirmAlert } from "react-confirm-alert";

class Vouchers extends Component {
  state = {
    data: {
      totalAmount: 0,
      cashAmount: "",
      checkAmount: "",
      voucherTypeId: "",
      collectorId: "",
      notes: "",
    },

    contentHeader: "سندات القبض",
    addButtonLabel: "اضافة سند قبض",
    addCollectorVoucherButtonLabel: "سند قبض جابي",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetVouchers",
    singleItemUrl: "/api/data/GetVoucher",
    addUrl: "/api/data/AddVoucher",
    addCollectorVoucherUrl: "/api/data/AddCollectorVoucher",
    deleteUrl: "/api/data/DeleteVoucher",

    inModalMode: false,
    inCollectorVoucherMode: false,
    modalHeaderAdd: "اضافة سند قبض",
    modalSubmitButtonLabelAdd: "اضافة سند قبض",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderCollectorVoucher: "اضاقة سند قبض لجابي",
    modalSubmitButtonLabelCollectorVoucher: "اضافة سند قبض",
    modalWidth: "50vw",

    voucherTypesDropdown: [],
    collectorsDropdown: [],
    voucherRecords: [],
    recordTypeId: null,
    recordTypeName: "",
    recordTypeAmount: null,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "رقم سند القبض",
        field: "ID",
      },
      {
        id: 2,
        label: "المبلغ الكلي",
        field: "TOTAL_AMOUNT",
      },
      {
        id: 3,
        label: "مبلغ النقد",
        field: "CASH_AMOUNT",
      },
      {
        id: 4,
        label: "مبلغ الصكوك",
        field: "CHECK_AMOUNT",
      },
      {
        id: 5,
        label: "اسم الجابي",
        field: "COLLECTOR_NAME",
      },
      {
        id: 6,
        label: "اسم المسدد",
        field: "PAYER_NAME",
      },
      {
        id: 7,
        label: "تم التسوية",
        field: "IS_SETTELED",
        width: "140px",
        cellRenderer: ({ data }) => {
          return data.IS_SETTELED === "Y" ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;نعم&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#343A40",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;لا&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 8,
        label: "التفاصيل",
        field: "NOTES",
      },
      {
        id: 9,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 10,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="طباعة">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => this.handlePrint(data)}
                >
                  <i className="fa fa-print" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CB2A"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getVoucherTypesDropdown();
    this.getCollectorsDropdown();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inCollectorVoucherMode: false, inModalMode: true });
  }

  showCollectorVoucherForm() {
    this.setState({ inModalMode: false, inCollectorVoucherMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = null);
    });

    this.setState({
      inModalMode: false,
      inCollectorVoucherMode: false,
      data: data,
      voucherRecords: [],
      recordTypeId: null,
      recordTypeName: "",
      recordTypeAmount: null,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getVoucherTypesDropdown() {
    post(
      "/api/data/getVoucherTypesDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        voucherTypesDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  }

  getCollectorsDropdown() {
    post(
      "/api/data/getCollectorsDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        collectorsDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  }

  onChangeCollectorDropdown = (inputValue) => {
    post(
      "/api/data/getcollectorcollectedAmount",
      { collectorId: inputValue },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.updateForm(
          "totalAmount",
          apiResponse.data.respData.collectedAmounts
        );
      }
    });
  };

  handleDelete(row) {
    confirmAlert({
      title: "حذف سند قبض",
      message: "هل انت متأكد من حذف سند القبض",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);
    submitData["isFromCollector"] = this.state.inCollectorVoucherMode;
    submitData["list"] = this.state.voucherRecords;
    post(this.state.addUrl, submitData, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
          voucherPrint(
            apiResponse.data.respData.data.ID,
            apiResponse.data.respData.data.INSERT_DATE,
            "قطاع الغزلاني", //apiResponse.data.respData.data.AR_SECTOR_NAME,
            apiResponse.data.respData.data.TOTAL_AMOUNT,
            apiResponse.data.respData.data.CASH_AMOUNT,
            apiResponse.data.respData.data.CHECK_AMOUNT,
            apiResponse.data.respData.data.PAYER_NAME,
            apiResponse.data.respData.data.DISPLAY_NAME,
            apiResponse.data.respData.data.NOTES,
            apiResponse.data.respData.data.DETAILS
          );
        }
      }
    );
  }

  doCollectorVoucherSubmit() {
    const submitData = Object.assign({}, this.state.data);
    submitData["isFromCollector"] = this.state.inCollectorVoucherMode;
    post(this.state.addCollectorVoucherUrl, submitData, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
          voucherPrint(
            apiResponse.data.respData.data.ID,
            apiResponse.data.respData.data.INSERT_DATE,
            "قطاع الغزلاني", //apiResponse.data.respData.data.AR_SECTOR_NAME,
            apiResponse.data.respData.data.TOTAL_AMOUNT,
            apiResponse.data.respData.data.CASH_AMOUNT,
            apiResponse.data.respData.data.CHECK_AMOUNT,
            apiResponse.data.respData.data.PAYER_NAME,
            apiResponse.data.respData.data.DISPLAY_NAME,
            apiResponse.data.respData.data.NOTES,
            apiResponse.data.respData.data.DETAILS
          );
        }
      }
    );
  }

  handlePrint(data) {
    post(
      this.state.singleItemUrl,
      { id: data.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.getData();
        voucherPrint(
          apiResponse.data.respData.data.ID,
          apiResponse.data.respData.data.INSERT_DATE,
          "قطاع الغزلاني", //apiResponse.data.respData.data.AR_SECTOR_NAME,
          apiResponse.data.respData.data.TOTAL_AMOUNT,
          apiResponse.data.respData.data.CASH_AMOUNT,
          apiResponse.data.respData.data.CHECK_AMOUNT,
          apiResponse.data.respData.data.PAYER_NAME,
          apiResponse.data.respData.data.DISPLAY_NAME,
          apiResponse.data.respData.data.NOTES,
          apiResponse.data.respData.data.DETAILS
        );
      }
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB10"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showForm();
                }}
              />
            )}
            &nbsp;
            {sysJwt.isAuthorized(["CB10"]) && (
              <Button
                label={this.state.addCollectorVoucherButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showCollectorVoucherForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeaderAdd}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelAdd}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <div className="row">
            <div className="col-7">
              <Dropdown
                className="dropdown"
                value={this.state.recordTypeId}
                showClear={true}
                options={this.state.voucherTypesDropdown}
                filter={true}
                onChange={(e) => {
                  this.setState({
                    recordTypeId: e.target.value,
                    recordTypeName: this.state.voucherTypesDropdown.filter(
                      (u) => u.value === e.target.value
                    )[0].label,
                  });
                }}
              />
            </div>
            <div className="col-3">
              <InputText
                id="recordTypeAmount"
                value={this.state.recordTypeAmount}
                className="form-control form-control-sm"
                type="text"
                placeholder="المبلغ"
                onChange={(e) => {
                  this.setState({ recordTypeAmount: e.target.value });
                }}
              />
            </div>
            <div className="col-2">
              <Button
                label="اضافة"
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  if (
                    !empty(this.state.recordTypeId) &&
                    !empty(this.state.recordTypeAmount) //&&
                    // (this.state.voucherRecords.length == 0 ||
                    //   this.state.voucherRecords.filter(
                    //     (u) => u.recordTypeId === this.state.recordTypeId
                    //   ).length === 0)
                    && parseInt(this.state.recordTypeAmount) > 0
                  ) {
                    let id = this.state.recordTypeId;
                    let amount = this.state.recordTypeAmount;
                    let name = this.state.recordTypeName;
                    var data = this.state.data;
                    var totalAmoumt = data["totalAmount"] === null ? 0 : parseInt(data["totalAmount"]);
                    data["totalAmount"] = totalAmoumt + parseInt(amount);
                    this.setState({
                      data: data,
                      voucherRecords: [
                        ...this.state.voucherRecords,
                        {
                          recordTypeId: id,
                          recordTypeName: name,
                          recordTypeAmount: amount                          
                        },
                      ],
                    });
                  }
                }}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <table style={{ border: "1px solid black", width: "100%" }}>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>النوع</td>
                  <td style={{ border: "1px solid black" }}>المبلغ</td>
                </tr>
                {this.state.voucherRecords.map((item) => (
                  <tr style={{ border: "1px solid black" }}>
                    <td style={{ border: "1px solid black" }}>
                      {item.recordTypeName}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {item.recordTypeAmount}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={false}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "totalAmount",
                label: "المبلغ الكلي",
                disabled: true,
              },
              {
                type: "text",
                id: "cashAmount",
                label: "مبلغ النقد",
                disabled: false,
              },
              {
                type: "text",
                id: "checkAmount",
                label: "مبلغ الصكوك",
                disabled: false,
              },
              {
                type: "text",
                id: "payerName",
                label: "اسم المسدد",
                disabled: false,
              },
              {
                type: "text",
                id: "notes",
                label: "التفاصيل",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inCollectorVoucherMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.modalHeaderCollectorVoucher}
            </h5>
          }
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelCollectorVoucher}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doCollectorVoucherSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={false}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "totalAmount",
                label: "المبلغ الكلي",
                disabled: true,
              },
              {
                type: "text",
                id: "cashAmount",
                label: "مبلغ النقد",
                disabled: false,
              },
              {
                type: "text",
                id: "checkAmount",
                label: "مبلغ الصكوك",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "collectorId",
                label: "الجابي",
                options: this.state.collectorsDropdown,
                disabled: false,
                customOnChangeHandler: this.onChangeCollectorDropdown,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
        <div className="row">
          <div className="col-4">
            <p>
              {" "}
              المجموع :{" "}
              {this.state.tableData.length > 0
                ? this.state.tableData
                    .reduce((s, e) => s + e.TOTAL_AMOUNT, 0)
                    .toLocaleString()
                : 0}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Vouchers;
