import React, { Component, Fragment } from "react";
import homelogo from "../../images/homeLoad.png";
import { post } from "../../utils/sysAxios";
import sysJwt from "../../utils/sysJwt";
import { toastError } from "../../utils/sysToast";
import "../../styles/table.css";
import ImageMapper from "react-img-mapper";
import { Button } from "primereact/button";
import queryString from "query-string";

class Home extends Component {
  state = {};

  componentDidMount() {
    if (sysJwt.getIsDefaultPwd() === "true") {
      if (window.location.pathname !== "/dashboard/changepassword") {
        toastError(
          "يرجى تغيير كلمة السر الافتراضية للنظام بكلمة سر جديدة خاصة بك"
        );
        this.sleep(2000).then(() =>
          window.location.replace("/dashboard/changepassword")
        );
      }
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="home-logo text-center"
          style={{ margin: "0 auto", "margin-top": "25px" }}
        >
          <img src={homelogo} width={1200} height={500} alt="HOME" />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
