/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import { post } from "../../../utils/sysAxios";
import { Button } from "primereact/button";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";
import { Dropdown } from "primereact/dropdown";
import { getSysDate3, empty } from "../../../utils/stringHelper";
import { ExportSheet } from "react-xlsx-sheet";
import XLSX from "xlsx";
import { collectorDueAmountExport } from "../../../utils/printFroms";

class CollectorsDueAmount extends Component {
  state = {
    contentHeader: "مبالغ الجباة",
    dataUrl: "/api/data/GetCollectorsDueAmounts",

    sectorId: "",
    collectorId: "",
    hasDueAmount: "",

    sectorsDropdown: [],
    collectorsDropdown: [],
    hasDueAmountsDropdown: [
      { label: "مطلوب", value: true },
      { label: "غير مطلوب", value: false },
    ],

    xlsxHeaders: [],
    xlsxData: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "اسم الجابي",
        field: "FULL_NAME",
      },
      {
        id: 3,
        label: "المبلغ المطلوب",
        field: "DUE_AMOUNT",
        width: "140px",
        cellRenderer: ({ data }) => {
          return data.DUE_AMOUNT === 0 ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;{data.DUE_AMOUNT}&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#343A40",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;{data.DUE_AMOUNT}&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 4,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },
      {
        id: 5,
        label: "رقم الهاتف",
        field: "PHONE_NO",
      },
      {
        id: 6,
        label: "اسم المستخدم",
        field: "USERNAME",
      },
      {
        id: 7,
        label: "محظور",
        field: "IS_BLOCKED",
        width: "140px",
        cellRenderer: ({ data }) => {
          return data.IS_BLOCKED === "Y" ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;نعم&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#343A40",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;لا&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 8,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 9,
        label: "تاريخ التعديل",
        field: "LAST_UPDATE",
      },
    ],
  };

  componentDidMount() {
    this.getSectorsDropdown();
  }

  getData() {
    post(
      this.state.dataUrl,
      {
        sectorId: this.state.sectorId,
        collectorId: this.state.collectorId,
        hasDueAmount: this.state.hasDueAmount,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        tableData: apiResponse.data.respData.data,
        xlsxData: apiResponse.data.respData.data,
      });
      this.buildExcelTable(apiResponse.data.respData.data);
    });
  }

  getSectorsDropdown() {
    post("/api/data/getSectorsDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          sectorsDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getCollectorsDropdown = (input) => {
    post(
      "/api/data/getCollectorsDropdown",
      { sectorId: input },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        collectorsDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  };

  buildExcelTable(reportData) {
    let xlsxHeaders = [];
    let co = 1;
    if (reportData.length !== 0) {
      for (var property in reportData[0]) {
        if (reportData[0].hasOwnProperty(property)) {
          co++;
          xlsxHeaders.push({ title: property, dataIndex: property });
        }
      }
      this.setState({
        xlsxHeaders: xlsxHeaders,
      });
    }
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            القطاع:
            <Dropdown
              value={this.state.sectorId}
              showClear={true}
              options={this.state.sectorsDropdown}
              filter={true}
              onChange={(e) => {
                this.setState({ sectorId: e.target.value });
                this.getCollectorsDropdown(e.target.value);
              }}
            />
            &nbsp; حالة الطلب:
            <Dropdown
              value={this.state.hasDueAmount}
              showClear={true}
              options={this.state.hasDueAmountsDropdown}
              filter={true}
              onChange={(e) => this.setState({ hasDueAmount: e.target.value })}
            />
            &nbsp; الجابي:
            <Dropdown
              value={this.state.collectorId}
              showClear={true}
              options={this.state.collectorsDropdown}
              filter={true}
              onChange={(e) => this.setState({ collectorId: e.target.value })}
            />
            &nbsp;
            <Button
              label="بحث"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
        <div className="row">
          <div className="col-4">
            <p>
              {" "}
              المجموع :{" "}
              {this.state.tableData.length > 0
                ? this.state.tableData
                    .reduce((s, e) => s + e.DUE_AMOUNT, 0)
                    .toLocaleString()
                : 0}
            </p>
          </div>
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <ExportSheet
                header={this.state.xlsxHeaders}
                dataSource={this.state.xlsxData}
                fileName="DataExport"
                isRequiredNameDate={false}
                xlsx={XLSX}
              >
                <Button
                  className="p-button-success"
                  label="تصدير اكسل"
                  icon="fa fa-file-excel-o"
                />
              </ExportSheet>
            ) : (
              ""
            )}
          </div>
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <Button
                className="p-button-secondary"
                label="تصدير PDF"
                icon="fa fa-file-pdf-o"
                onClick={() => {
                  collectorDueAmountExport(this.state.tableData);
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CollectorsDueAmount;
