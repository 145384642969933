/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";

class UserManagement extends Component {
  state = {
    data: {
      Username: "",
      FirstName: "",
      LastName: "",
      UserRole: "",
      MunicipalityId: "",
      SectorId: ""
    },

    contentHeader: "المستخدمين",
    addButtonLabel: "اضافة مستخدم",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getusers",
    singleItemUrl: "/api/data/getuser",
    addUrl: "/api/data/adduser",
    editUrl: "/api/data/edituser",
    deleteUrl: "/api/data/deleteuser",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة مستخدم",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل مستخدم",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    rolesDropdown: [],
    municipalitiesDropdown: [],
    sectorsDropdown: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "اسم المستخدم",
        field: "USERNAME",
      },
      {
        id: 2,
        label: "محظور",
        field: "IS_BLOCKED",
        width: "140px",
        cellRenderer: ({ data }) => {
          return data.IS_BLOCKED === "Y" ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;نعم&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#343A40",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;لا&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 3,
        label: "تاريخ التسجيل",
        field: "INSERT_DATE",
      },
      {
        id: 4,
        label: "الاسم الاول",
        field: "FIRST_NAME",
      },
      {
        id: 5,
        label: "الاسم الاخير",
        field: "LAST_NAME",
      },
      {
        id: 6,
        label: "الصلاحيات",
        field: "ROLE_NAME",
      },
      {
        id: 7,
        label: "البلدية",
        field: "AR_MUNICIPALITY_NAME",
      },
      {
        id: 8,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },      
      {
        id: 9,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CAC0"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC1"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC2"]) && (
                <div className="inner" title="حظر">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleBlock(data)}
                  >
                    <i className="fa fa-ban" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC3"]) && (
                <div className="inner" title="رفع الحظر">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleUnblock(data)}
                  >
                    <i className="fa fa-check" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC4"]) && (
                <div className="inner" title="اعادة كلمة السر الافتراضية">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleResetPassword(data)}
                  >
                    <i className="fa fa-key" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });

    this.getRolesDropdown();
    this.getMunicipalitesDropdown();
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = null);
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      rolesDropdown: [],
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { UserId: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["Username"] = apiResponse.data.respData.data[0].USERNAME;
      data["FirstName"] = apiResponse.data.respData.data[0].FIRST_NAME;
      data["LastName"] = apiResponse.data.respData.data[0].LAST_NAME;
      data["UserRole"] = apiResponse.data.respData.data[0].ROLE_ID_FK;
      data["MunicipalityId"] = apiResponse.data.respData.data[0].MUNICIPALITY_ID_FK;
      data["SectorId"] = apiResponse.data.respData.data[0].SECTOR_ID_FK;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  getRolesDropdown() {
    post("/api/data/getrolesdropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          rolesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "ROLE_NAME",
            "ID"
          ),
        });
      }
    );
  }

  getMunicipalitesDropdown() {
    post("/api/data/GetMunicipalitiesDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          municipalitiesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getSectorsDropdown = (input) => {
    post("/api/data/GetSectorsByMunicipilityDropdown", { municipalityId: input}, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          sectorsDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف المستخدم",
      message: "هل انت متأكد من حذف هذا المستخدم؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { UserId: row.ID, Username: row.USERNAME },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  handleBlock(row) {
    post(
      "/api/data/blockuser",
      { UserId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleUnblock(row) {
    post(
      "/api/data/unblockuser",
      { UserId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleResetPassword(row) {
    post(
      "/api/data/resetpassword",
      { UserId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    const data = new FormData();

    data.append("requestData", JSON.stringify(submitData));

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      data,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CABF"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "Username",
                label: "اسم المستخدم",
                disabled: this.state.inEditMode ? true : false,
              },
              {
                type: "text",
                id: "FirstName",
                label: "الاسم الاول",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "UserRole",
                label: "الصلاحيات",
                options: this.state.rolesDropdown,
                disabled: false,
              },
              {
                type: "text",
                id: "LastName",
                label: "الاسم الاخير",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "MunicipalityId",
                label: "البلدية",
                options: this.state.municipalitiesDropdown,
                customOnChangeHandler: this.getSectorsDropdown,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "SectorId",
                label: "القطاع",
                options: this.state.sectorsDropdown,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default UserManagement;
