import { toast } from "react-toastify";

export function toastError(errorMessage) {
  toast.error(errorMessage, {
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_LEFT,
    className: "error-toast sys-toast",
  });
}

export function toastSuccess(successMessage) {
  toast.info(successMessage, {
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_LEFT,
    className: "info-toast sys-toast",
  });
}
