/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";

class Contracts extends Component {
  state = {
    data: {
      fullName: "",
      tradeName: "",
      phoneNo: "",
      signSize: "",
      signTypeId: "",
      latitude: "",
      longtude: "",
      subscriberCategoryId: "",
      municipalityPropertyId: "",
      signTypeId: "",
      startDate: "",
      endDate: "",
      startBillingDate: "",
      rentAmount: "",
    },

    contentHeader: "العقود",
    addButtonLabel: "اضافة عقد",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetContracts",
    singleItemUrl: "/api/data/GetContract",
    addUrl: "/api/data/AddContract",
    editUrl: "/api/data/EditContract",
    deleteUrl: "/api/data/DeleteContract",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة عقد",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل عقد",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    categoriesDropdown: [],
    municipalityPropertyDropdown: [],
    signTypesDropdown: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "اسم العقد",
        field: "FULL_NAME",
      },
      {
        id: 3,
        label: "الاسم التجاري",
        field: "TRADE_NAME",
      },
      {
        id: 4,
        label: "مبلغ الايجار السنوي",
        field: "RENT_AMOUNT",
      },
      {
        id: 5,
        label: "نوع النشاط",
        field: "AR_CATEGORY_NAME",
      },
      {
        id: 6,
        label: "رقم الهاتف",
        field: "PHONE_NO",
      },
      {
        id: 7,
        label: "المنطقة",
        field: "AR_ZONE_NAME",
      },
      {
        id: 8,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },
      {
        id: 9,
        label: "العنوان الكامل",
        field: "ADDRESS",
      },
      {
        id: 10,
        label: "مساحة المكان",
        field: "SITE_AREA",
      },
      {
        id: 11,
        label: "مساحة لوجة الاعلان",
        field: "SIGN_SIZE",
      },
      {
        id: 12,
        label: "لغة الاعلان",
        field: "SIGN_LANG",
      },
      {
        id: 13,
        label: "تاريخ بدأ الفوترة",
        field: "START_BILLING_DATE",
      },
      {
        id: 14,
        label: "Latitude",
        field: "LATITUDE",
      },
      {
        id: 15,
        label: "Longtude",
        field: "LONGTUDE",
      },
      {
        id: 16,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 17,
        label: "تاريخ التعديل",
        field: "LAST_UPDATE",
      },
      {
        id: 18,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB22"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB23"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getCategoriesDropdown();
    this.getMunicipilityPropertyDropdown();
    this.getSignTypesDropdown();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = null);
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["fullName"] = apiResponse.data.respData.data[0].FULL_NAME;
      data["tradeName"] = apiResponse.data.respData.data[0].TRADE_NAME;
      data["phoneNo"] = apiResponse.data.respData.data[0].PHONE_NO;
      data["signSize"] = apiResponse.data.respData.data[0].SIGN_SIZE;
      data["signTypeId"] = apiResponse.data.respData.data[0].SIGN_TYPE_ID;
      data["latitude"] = apiResponse.data.respData.data[0].LATITUDE;
      data["longtude"] = apiResponse.data.respData.data[0].LONGTUDE;
      data["subscriberCategoryId"] =
        apiResponse.data.respData.data[0].SUBSCRIBER_CATEGORY_ID_FK;
      data["municipalityPropertyId"] =
        apiResponse.data.respData.data[0].MUNICICALITY_PROPERTY_ID_FK;
      data["signTypeId"] = apiResponse.data.respData.data[0].SIGN_TYPE_ID_FK;
      data["startDate"] = apiResponse.data.respData.data[0].START_DATE;
      data["endDate"] = apiResponse.data.respData.data[0].END_DATE;
      data["startBillingDate"] =
        apiResponse.data.respData.data[0].START_BILLING_DATE;
      data["rentAmount"] = apiResponse.data.respData.data[0].RENT_AMOUNT;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  getCategoriesDropdown() {
    post(
      "/api/data/GetSubscriberCategoriesDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        categoriesDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  }

  getMunicipilityPropertyDropdown() {
    post(
      "/api/data/GetMunicipalityPropertyDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        municipalityPropertyDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  }

  getSignTypesDropdown() {
    post(
      "/api/data/getSignTypesDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        signTypesDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف العقد",
      message: "هل انت متأكد من حذف هذه العقد؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB21"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "fullName",
                label: "الاسم الكامل",
                disabled: false,
              },
              {
                type: "text",
                id: "tradeName",
                label: "الاسم التجاري",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "municipalityPropertyId",
                label: "اسم الملك",
                options: this.state.municipalityPropertyDropdown,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "subscriberCategoryId",
                label: "نوع النشاط",
                options: this.state.categoriesDropdown,
                disabled: false,
              },
              {
                type: "text",
                id: "phoneNo",
                label: "رقم الهاتف",
                disabled: false,
              },
              {
                type: "text",
                id: "signSize",
                label: "مساحة الاعلان",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "signTypeId",
                label: "لغة الاعلان",
                options: this.state.signTypesDropdown,
                disabled: false,
              },
              {
                type: "date",
                id: "startDate",
                label: "تاريخ بدأ العقد",
                disabled: false,
              },
              {
                type: "date",
                id: "endDate",
                label: "تاريخ انتهاء العقدء",
                disabled: false,
              },
              {
                type: "date",
                id: "startBillingDate",
                label: "تاريخ بدأ الفوترة",
                disabled: false,
              },
              {
                type: "text",
                id: "rentAmount",
                label: "مبلغ الايجار السنوي",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default Contracts;
