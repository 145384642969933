/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post } from "../../../utils/sysAxios";
import { Button } from "primereact/button";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { toastError } from "../../../utils/sysToast";
import { getSysDate3, empty } from "../../../utils/stringHelper";
import { ExportSheet } from "react-xlsx-sheet";
import XLSX from "xlsx";
import { followUpExport } from "../../../utils/printFroms";

class FollowUp extends Component {
  state = {
    contentHeader: "متابعة التحصيل",

    dataUrl: "/api/data/GetPaymentsAndVisits",

    dates: "",
    sectorId: "",
    collectorId: "",
    subscriberId: "",

    sectorsDropdown: [],
    subscribersDropdown: [],
    collectorsDropdown: [],

    xlsxHeaders: [],
    xlsxData: [],
    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "القيمة",
        field: "VALUE",
      },
      {
        id: 2,
        label: "اسم المشترك",
        field: "FULL_NAME",
      },
      {
        id: 3,
        label: "الاسم التجاري",
        field: "TRADE_NAME",
      },
      {
        id: 4,
        label: "اسم الجابي",
        field: "COLLECTOR_NAME",
      },
      {
        id: 5,
        label: "المنطقة",
        field: "AR_ZONE_NAME",
      },
      {
        id: 6,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },
      {
        id: 7,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 8,
        label: "",
        field: "SYS_ACTIONS",
        width: "50px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="الموقع">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/search/?api=1&query=" +
                        data.LATITUDE +
                        "," +
                        data.LONGTUDE,
                      "_blank"
                    )
                  }
                >
                  <i className="fa fa-map-marker" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getSectorsDropdown();
  }

  getData() {
    if (!this.state.dates[0] || !this.state.dates[1]) {
      toastError("يرجى اختيار من تاريخ الى تاريخ");
    } else {
      post(
        this.state.dataUrl,
        {
          dateFrom: this.state.dates[0],
          dateTo: this.state.dates[1],
          sectorId: this.state.sectorId,
          subscriberId: this.state.subscriberId,
          collectorId: this.state.collectorId,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        this.setState({
          tableData: apiResponse.data.respData.data,
          xlsxData: apiResponse.data.respData.data,
        });
        this.buildExcelTable(apiResponse.data.respData.data);
      });
    }
  }

  getSectorsDropdown() {
    post("/api/data/getSectorsDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          sectorsDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getSubscribersDropdown = (input) => {
    post(
      "/api/data/getSubscribersDropdown",
      { sectorId: input },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        subscribersDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  };

  getCollectorsDropdown = (input) => {
    post(
      "/api/data/getCollectorsBySectorDropdown",
      { sectorId: input },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        collectorsDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  };

  buildExcelTable(reportData) {
    let xlsxHeaders = [];
    let co = 1;
    if (reportData.length !== 0) {
      for (var property in reportData[0]) {
        if (reportData[0].hasOwnProperty(property)) {
          co++;
          xlsxHeaders.push({ title: property, dataIndex: property });
        }
      }
      this.setState({
        xlsxHeaders: xlsxHeaders,
      });
    }
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            التاريخ:
            <Calendar
              selectionMode="range"
              showIcon="true"
              value={this.state.dates}
              className="flex-item-left mr-2"
              onChange={(e) => this.setState({ dates: e.value })}
              style={{ width: "250px" }}
            ></Calendar>
            &nbsp; القطاع:
            <Dropdown
              value={this.state.sectorId}
              showClear={true}
              options={this.state.sectorsDropdown}
              filter={true}
              onChange={(e) => {
                this.setState({ sectorId: e.target.value });
                this.getSubscribersDropdown(e.target.value);
                this.getCollectorsDropdown(e.target.value);
              }}
            />
            &nbsp; المشترك:
            <Dropdown
              value={this.state.subscriberId}
              showClear={true}
              options={this.state.subscribersDropdown}
              filter={true}
              onChange={(e) => this.setState({ subscriberId: e.target.value })}
            />
            &nbsp; الجابي:
            <Dropdown
              value={this.state.collectorId}
              showClear={true}
              options={this.state.collectorsDropdown}
              filter={true}
              onChange={(e) => this.setState({ collectorId: e.target.value })}
            />
            &nbsp;
            <Button
              label="بحث"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
        <div className="row">
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <ExportSheet
                header={this.state.xlsxHeaders}
                dataSource={this.state.xlsxData}
                fileName="DataExport"
                isRequiredNameDate={false}
                xlsx={XLSX}
              >
                <Button
                  className="p-button-success"
                  label="تصدير اكسل"
                  icon="fa fa-file-excel-o"
                />
              </ExportSheet>
            ) : (
              ""
            )}
          </div>
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <Button
                className="p-button-secondary"
                label="تصدير PDF"
                icon="fa fa-file-pdf-o"
                onClick={() => {
                  followUpExport(
                    this.state.tableData,
                    this.state.dates ? getSysDate3(this.state.dates[0]) : "",
                    this.state.dates ? getSysDate3(this.state.dates[1]) : ""
                  );
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FollowUp;
