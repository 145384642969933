export function apiDataToDropdownList(apiData, label, value) {
  var options = [];

  for (var i = 0; i < apiData.length; i++) {
    var option = {
      label: apiData[i][label],
      value: apiData[i][value],
    };
    options.push(option);
  }

  return options;
}

export function getStockColor(qty, alertList) {
  for (var i = 0; i < alertList.length; i++) {
    if (alertList[i].COUNT_ALERT <= qty) {
      return alertList[i].COLOR_ALERT;
    }
  }
  return "black";
}

export function getRandomColor() {
  return "#" + (((1 << 24) * Math.random()) | 0).toString(16);
}
