import "bootstrap/dist/css/bootstrap.css";
import React from "react";
//import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "font-awesome/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/nova-alt/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "./styles/confirmBox.css";
import "./styles/dashboard.css";
import "./styles/loginForm.css";
import "./styles/statusCodePages.css";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

/*ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);
*/
//registerServiceWorker();
root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);
