/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import { post } from "../../../utils/sysAxios";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { toastError } from "../../../utils/sysToast";
import { getSysDate3, empty } from "../../../utils/stringHelper";
import { ExportSheet } from "react-xlsx-sheet";
import XLSX from "xlsx";
import { billsExport } from "../../../utils/printFroms";

class Bills extends Component {
  state = {
    contentHeader: "الفواتير",
    addButtonLabel: "اضافة فاتورة",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetBills",
    singleItemUrl: "/api/data/GetBill",
    deleteUrl: "/api/data/DeleteBill",

    dates: "",
    sectorId: "",
    serviceId: "",
    subscriberId: "",

    sectorsDropdown: [],
    subscribersDropdown: [],
    servicesDropdown: [],

    xlsxHeaders: [],
    xlsxData: [],
    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "رقم الفاتورة",
        field: "ID",
        width: "150px",
      },
      {
        id: 2,
        label: "مبلغ الفاتورة",
        field: "AMOUNT",
      },
      {
        id: 3,
        label: "تاريخ الاستحقاق",
        field: "DUE_DATE",
      },
      {
        id: 4,
        label: "حالة التسديد",
        field: "IS_PAID",
        width: "150px",
        cellRenderer: ({ data }) => {
          return data.IS_PAID === "Y" ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;مسدد&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#343A40",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;غير مسدد&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 5,
        label: "الخدمة",
        field: "AR_SERVICE_NAME",
      },
      {
        id: 6,
        label: "اسم المشترك",
        field: "FULL_NAME",
      },
      {
        id: 7,
        label: "الاسم التجاري",
        field: "TRADE_NAME",
      },
      {
        id: 8,
        label: "المنطقة",
        field: "AR_ZONE_NAME",
      },
      {
        id: 9,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },
      {
        id: 10,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 11,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB24"]) && data.IS_PAID === "N" && (
                <div className="inner" title="تسديد">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handlePay(data)}
                  >
                    <i className="fa fa-money" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getSectorsDropdown();
    this.getServicesDropdown();
  }

  getSectorsDropdown() {
    post("/api/data/getSectorsDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          sectorsDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getSubscribersDropdown = (input) => {
    post(
      "/api/data/getSubscribersDropdown",
      { sectorId: input },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        subscribersDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  };

  getServicesDropdown() {
    post(
      "/api/data/getServicesDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        servicesDropdown: apiDataToDropdownList(
          apiResponse.data.respData.data,
          "label",
          "value"
        ),
      });
    });
  }

  getData() {
    if (!this.state.dates[0] || !this.state.dates[1]) {
      toastError("يرجى اختيار من تاريخ الى تاريخ");
    } else {
      post(
        this.state.dataUrl,
        {
          dateFrom: this.state.dates[0],
          dateTo: this.state.dates[1],
          sectorId: this.state.sectorId,
          subscriberId: this.state.subscriberId,
          serviceId: this.state.serviceId,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        this.setState({
          tableData: apiResponse.data.respData.data,
          xlsxData: apiResponse.data.respData.data,
        });
        this.buildExcelTable(apiResponse.data.respData.data);
      });
    }
  }

  buildExcelTable(reportData) {
    let xlsxHeaders = [];
    let co = 1;
    if (reportData.length !== 0) {
      for (var property in reportData[0]) {
        if (reportData[0].hasOwnProperty(property)) {
          co++;
          xlsxHeaders.push({ title: property, dataIndex: property });
        }
      }
      this.setState({
        xlsxHeaders: xlsxHeaders,
      });
    }
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف الفاتورة",
      message: "هل انت متأكد من حذف هذه الفاتورة؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  handlePay(row) {
    confirmAlert({
      title: "الموافقة على تسديد فاتورة",
      message: "هل انت متأكد من الموافقة على تسديد فاتورة",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              "/api/data/PayBill",
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              if (apiResponse.data.respCode === 0) {
                this.props.updateIsLoading(false);
                this.getData();
              }
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            التاريخ:
            <Calendar
              selectionMode="range"
              showIcon="true"
              value={this.state.dates}
              className="flex-item-left mr-2"
              onChange={(e) => this.setState({ dates: e.value })}
              style={{ width: "250px" }}
            ></Calendar>
            &nbsp; القطاع:
            <Dropdown
              value={this.state.sectorId}
              showClear={true}
              options={this.state.sectorsDropdown}
              filter={true}
              onChange={(e) => {
                this.setState({ sectorId: e.target.value });
                this.getSubscribersDropdown(e.target.value);
              }}
            />
            &nbsp; المشترك:
            <Dropdown
              value={this.state.subscriberId}
              showClear={true}
              options={this.state.subscribersDropdown}
              filter={true}
              onChange={(e) => this.setState({ subscriberId: e.target.value })}
            />
            &nbsp; الخدمة:
            <Dropdown
              value={this.state.serviceId}
              showClear={true}
              options={this.state.servicesDropdown}
              filter={true}
              onChange={(e) => this.setState({ serviceId: e.target.value })}
            />
            &nbsp;
            <Button
              label="بحث"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
        <div className="row">
          <div className="col-4">
            <p>
              {" "}
              المجموع :{" "}
              {this.state.tableData.length > 0
                ? this.state.tableData
                    .reduce((s, e) => s + e.AMOUNT, 0)
                    .toLocaleString()
                : 0}
            </p>
          </div>
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <ExportSheet
                header={this.state.xlsxHeaders}
                dataSource={this.state.xlsxData}
                fileName="DataExport"
                isRequiredNameDate={false}
                xlsx={XLSX}
              >
                <Button
                  className="p-button-success"
                  label="تصدير اكسل"
                  icon="fa fa-file-excel-o"
                />
              </ExportSheet>
            ) : (
              ""
            )}
          </div>
          <div className="col-2">
            {this.state.xlsxData.length > 0 ? (
              <Button
                className="p-button-secondary"
                label="تصدير PDF"
                icon="fa fa-file-pdf-o"
                onClick={() => {
                  billsExport(
                    this.state.tableData,
                    this.state.dates ? getSysDate3(this.state.dates[0]) : "",
                    this.state.dates ? getSysDate3(this.state.dates[1]) : ""
                  );
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Bills;
