/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";
import { mainVoucherBankPrint } from "../../../utils/printFroms";

class MainVouchersBank extends Component {
  state = {
    data: {
      totalAmount: "",
      cashAmount: "",
      checkAmount: "",
      employeeName: "",
      fromDate: "",
      toDate: "",
    },

    contentHeader: "سندات الاخراج المصرفية",
    addButtonLabel: "اضافة سند اخراج",
    addSectorOutgoingVoucherButtonLabel: "سند اخراج مصرف",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetMainVouchersBank",
    singleItemUrl: "/api/data/GetMainVoucherBank",
    addUrl: "/api/data/AddMainVoucherBank",

    inModalMode: false,
    modalHeaderAdd: "اضافة سند اخراج",
    modalSubmitButtonLabelAdd: "اضافة سند اخراج",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalWidth: "50vw",

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "رقم سند الاخراج",
        field: "ID",
      },
      {
        id: 2,
        label: "المبلغ الكلي",
        field: "TOTAL_AMOUNT",
      },
      {
        id: 3,
        label: "مبلغ النقد",
        field: "CASH_AMOUNT",
      },
      {
        id: 4,
        label: "مبلغ الصكوك",
        field: "CHECK_AMOUNT",
      },
      {
        id: 5,
        label: "بواسطة",
        field: "USER",
      },
      {
        id: 6,
        label: "اسم الموضف",
        field: "EMPLOYEE_NAME",
      },
      {
        id: 7,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 8,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="طباعة">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => this.handlePrint(data)}
                >
                  <i className="fa fa-print" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inSectorOutgoingVoucherMode: false, inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = null);
    });

    this.setState({
      inModalMode: false,
      inSectorOutgoingVoucherMode: false,
      data: data,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getSectorcollectedAmount() {
    post(
      "/api/data/getBoxcollectedAmount",
      { fromDate: this.state.data.fromDate, toDate: this.state.data.toDate },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.updateForm(
          "totalAmount",
          apiResponse.data.respData.collectedAmounts
        );
        //this.updateForm("cashAmount", apiResponse.data.respData.cashAmounts);
        //this.updateForm("checkAmount", apiResponse.data.respData.checkAmounts);
      }
    });
  }

  handlePrint(data) {
    post(
      "/api/data/PrintMainVoucherBank",
      { mainVoucherBankId: data.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
        mainVoucherBankPrint(
          apiResponse.data.respData.data.ID,
          apiResponse.data.respData.data.INSERT_DATE,
          "بلدية الموصل", //apiResponse.data.respData.data.AR_SECTOR_NAME,
          apiResponse.data.respData.data.TOTAL_AMOUNT,
          apiResponse.data.respData.data.CASH_AMOUNT,
          apiResponse.data.respData.data.CHECK_AMOUNT,
          apiResponse.data.respData.data.DISPLAY_NAME,
          apiResponse.data.respData.data.EMPLOYEE_NAME
        );
      }
    });
  }

  doSubmit() {
    post(this.state.addUrl, this.state.data, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
          mainVoucherBankPrint(
            apiResponse.data.respData.data.ID,
            apiResponse.data.respData.data.INSERT_DATE,
            "بلدية الموصل", //apiResponse.data.respData.data.AR_SECTOR_NAME,
            apiResponse.data.respData.data.TOTAL_AMOUNT,
            apiResponse.data.respData.data.CASH_AMOUNT,
            apiResponse.data.respData.data.CHECK_AMOUNT,
            apiResponse.data.respData.data.DISPLAY_NAME,
            apiResponse.data.respData.data.EMPLOYEE_NAME
          );
        }
      }
    );
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB16"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeaderAdd}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelAdd}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label={"احتساب المبلغ"}
                icon={"fa fa-money"}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.getSectorcollectedAmount()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={false}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "datetime",
                id: "fromDate",
                label: "من تاريخ",
                disabled: false,
              },
              {
                type: "datetime",
                id: "toDate",
                label: "الى تاريخ",
                disabled: false,
              },
              {
                type: "text",
                id: "totalAmount",
                label: "المبلغ الكلي",
                disabled: true,
              },
              {
                type: "text",
                id: "cashAmount",
                label: "مبلغ النقد",
                disabled: false,
              },
              {
                type: "text",
                id: "checkAmount",
                label: "مبلغ الصكوك",
                disabled: false,
              },
              {
                type: "text",
                id: "employeeName",
                label: "اسم الموظف",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
        <div className="row">
          <div className="col-4">
            <p>
              {" "}
              المجموع :{" "}
              {this.state.tableData.length > 0
                ? this.state.tableData
                    .reduce((s, e) => s + e.TOTAL_AMOUNT, 0)
                    .toLocaleString()
                : 0}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MainVouchersBank;
