/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";
import { ExportSheet } from "react-xlsx-sheet";
import XLSX from "xlsx";

class Subscribers extends Component {
  state = {
    data: {
      fullName: "",
      tradeName: "",
      fullAddress: "",
      phoneNo: "",
      siteArea: "",
      noOfDoors: "",
      signSize: "",
      signTypeId: "",
      latitude: "",
      longtude: "",
      zoneId: "",
      subscriberCategoryId: "",
      documentAttachments: "",
      shopAttachments: "",
      contractAttachments: ""
    },

    contentHeader: "المشتركين",
    addButtonLabel: "اضافة مشترك",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetSubscribers",
    singleItemUrl: "/api/data/GetSubscriber",
    addUrl: "/api/data/AddSubscriber",
    editUrl: "/api/data/EditSubscriber",
    deleteUrl: "/api/data/DeleteSubscriber",

    inEditMode: false,
    inModalMode: false,
    inImageMode: false,
    modalHeaderAdd: "اضافة مشترك",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل مشترك",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",
    imageModalWidth: "70vw",

    editEntityId: 0,

    zonesDropdown: [],
    signTypesDropdown: [],
    categoriesDropdown: [],

    subscriberImages: [],

    xlsxHeaders: [],
    xlsxData: [],
    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "اسم المشترك",
        field: "FULL_NAME",
      },
      {
        id: 3,
        label: "الاسم التجاري",
        field: "TRADE_NAME",
      },
      {
        id: 4,
        label: "نوع النشاط",
        field: "AR_CATEGORY_NAME",
      },
      {
        id: 5,
        label: "رقم الهاتف",
        field: "PHONE_NO",
      },
      {
        id: 6,
        label: "المنطقة",
        field: "AR_ZONE_NAME",
      },
      {
        id: 7,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },
      {
        id: 8,
        label: "العنوان الكامل",
        field: "FULL_ADDRESS",
      },
      {
        id: 9,
        label: "مساحة المكان",
        field: "SITE_AREA",
      },
      {
        id: 10,
        label: "عدد الابواب",
        field: "NO_OF_DOORS",
      },
      {
        id: 11,
        label: "مساحة لوحة الاعلان",
        field: "SIGN_SIZE",
      },
      {
        id: 12,
        label: "لغة الاعلان",
        field: "AR_SIGN_TYPE",
      },
      {
        id: 13,
        label: "Latitude",
        field: "LATITUDE",
      },
      {
        id: 14,
        label: "Longtude",
        field: "LONGTUDE",
      },
      {
        id: 15,
        label: "الحالة",
        field: "IS_ACTIVE",
        width: "140px",
        cellRenderer: ({ data }) => {
          return data.IS_ACTIVE === "Y" ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;فعال&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#343A40",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;غير فعال&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 16,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 17,
        label: "تاريخ التعديل",
        field: "LAST_UPDATE",
      },
      {
        id: 18,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="عرض الصور">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => this.getSubscriberImages(data)}
                >
                  <i className="fa fa-eye" />
                </button>
              </div>
              <div className="inner" title="الموقع">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/search/?api=1&query=" +
                      data.LATITUDE +
                      "," +
                      data.LONGTUDE,
                      "_blank"
                    )
                  }
                >
                  <i className="fa fa-map-marker" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CB08"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB09"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB27"]) && (
                <div className="inner" title="ايقاف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDeactivate(data)}
                  >
                    <i className="fa fa-ban" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB26"]) && (
                <div className="inner" title="تفعيل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleActivate(data)}
                  >
                    <i className="fa fa-check" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getZonesDropdown();
    this.getSignTypesDropdown();
    this.getCategoriesDropdown();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = null);
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  closeImageModal() {
    this.setState({
      subscriberImages: [],
      inImageMode: false
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          tableData: apiResponse.data.respData.data,
          xlsxData: apiResponse.data.respData.data
        });
        this.buildExcelTable(apiResponse.data.respData.data);
      }
    );
  }

  buildExcelTable(reportData) {
    let xlsxHeaders = [];
    let co = 1;
    if (reportData.length !== 0) {
      for (var property in reportData[0]) {
        if (reportData[0].hasOwnProperty(property)) {
          co++;
          xlsxHeaders.push({ title: property, dataIndex: property });
        }
      }
      this.setState({
        xlsxHeaders: xlsxHeaders,
      });
    }
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["fullName"] = apiResponse.data.respData.data[0].FULL_NAME;
      data["tradeName"] = apiResponse.data.respData.data[0].TRADE_NAME;
      data["fullAddress"] = apiResponse.data.respData.data[0].FULL_ADDRESS;
      data["phoneNo"] = apiResponse.data.respData.data[0].PHONE_NO;
      data["siteArea"] = apiResponse.data.respData.data[0].SITE_AREA;
      data["noOfDoors"] = apiResponse.data.respData.data[0].NO_OF_DOORS;
      data["signSize"] = apiResponse.data.respData.data[0].SIGN_SIZE;
      data["signTypeId"] = apiResponse.data.respData.data[0].SIGN_TYPE_ID_FK;
      data["latitude"] = apiResponse.data.respData.data[0].LATITUDE;
      data["longtude"] = apiResponse.data.respData.data[0].LONGTUDE;
      data["zoneId"] = apiResponse.data.respData.data[0].ZONE_ID_FK;
      data["subscriberCategoryId"] = apiResponse.data.respData.data[0].SUBSCRIBER_CATEGORY_ID_FK;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  getSubscriberImages(row) {
    return post(
      "/api/data/GetSubscriberimages",
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        subscriberImages: apiResponse.data.respData.data,
        inImageMode: true,
      });
    });
  }

  getZonesDropdown() {
    post("/api/data/getZonesDropdown", { sectorId: sysJwt.getSectorId }, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          zonesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getSignTypesDropdown() {
    post("/api/data/GetSignTypesDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          signTypesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getCategoriesDropdown() {
    post("/api/data/GetSubscriberCategoriesDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          categoriesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف المشترك",
      message: "هل انت متأكد من حذف هذه المشترك؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  handleActivate(row) {
    post(
      "/api/data/activatesubscriber",
      { SubscriberId: row.ID },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleDeactivate(row) {
    post(
      "/api/data/deactivatesubscriber",
      { SubscriberId: row.ID },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    const data = new FormData();
    if (this.state.data.documentAttachments) {
      Array.from(this.state.data.documentAttachments).map((member) =>
        data.append("documentAttachments", member, member.name)
      );
    }

    if (this.state.data.shopAttachments) {
      Array.from(this.state.data.shopAttachments).map((member) =>
        data.append("shopAttachments", member, member.name)
      );
    }

    if (this.state.data.contractAttachments) {
      Array.from(this.state.data.contractAttachments).map((member) =>
        data.append("contractAttachments", member, member.name)
      );
    }

    data.append("requestData", JSON.stringify(submitData));

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      data,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB07"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "fullName",
                label: "الاسم الكامل",
                disabled: false,
              },
              {
                type: "text",
                id: "tradeName",
                label: "الاسم التجاري",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "subscriberCategoryId",
                label: "نوع النشاط",
                options: this.state.categoriesDropdown,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "zoneId",
                label: "المنطقة",
                options: this.state.zonesDropdown,
                disabled: false,
              },
              {
                type: "text",
                id: "fullAddress",
                label: "العنوان الكامل",
                disabled: false,
              },
              {
                type: "text",
                id: "phoneNo",
                label: "رقم الهاتف",
                disabled: false,
              },
              {
                type: "text",
                id: "siteArea",
                label: "مساحة المكان",
                disabled: false,
              },
              {
                type: "text",
                id: "noOfDoors",
                label: "عدد الابواب",
                disabled: false,
              },
              {
                type: "text",
                id: "signSize",
                label: "مساحة الاعلان",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "signTypeId",
                label: "لغة الاعلان",
                options: this.state.signTypesDropdown,
                disabled: false,
              },
              {
                type: "text",
                id: "latitude",
                label: "Latitude",
                disabled: false,
              },
              {
                type: "text",
                id: "longtude",
                label: "Longtude",
                disabled: false,
              },
              {
                type: "multiplefileupload",
                id: "documentAttachments",
                label: "Document Attachments",
                disabled: false,
              },
              {
                type: "multiplefileupload",
                id: "shopAttachments",
                label: "Shop Attachments",
                disabled: false,
              },
              {
                type: "multiplefileupload",
                id: "contractAttachments",
                label: "Contract Attachments",
                disabled: false,
              }
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inImageMode}
          style={{ width: this.state.imageModalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeImageModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {"الصور"}
            </h5>
          }
          footer={
            <div>
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeImageModal()}
              />
            </div>
          }
        >
          <div>
            {this.state.subscriberImages.map((image) => (
              <img key={image.ID} src={image.PATH + "?token=" + sessionStorage.getItem("748784")} style={{ marginBottom: '80px' }} />
            ))}
          </div>
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
        <div className="col-2">
          {this.state.xlsxData.length > 0 ? (
            <ExportSheet
              header={this.state.xlsxHeaders}
              dataSource={this.state.xlsxData}
              fileName="DataExport"
              isRequiredNameDate={false}
              xlsx={XLSX}
            >
              <Button
                className="p-button-success"
                label="تصدير اكسل"
                icon="fa fa-file-excel-o"
              />
            </ExportSheet>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Subscribers;
