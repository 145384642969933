import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import sidebarBg from "../../images/bgSide.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import {
  FaUsers,
  FaChartArea,
  FaServer,
  FaUserCheck,
  FaWhmcs,
  FaUserShield,
  FaInfo,
  FaLock,
  FaPowerOff,
  FaBookReader,
  FaCity,
  FaMoneyBillAlt,
  FaFileSignature,
  FaUniversity,
  FaCalculator,
  FaList,
  FaCheck,
} from "react-icons/fa";
import "../../styles/App.scss";
import sysJwt from "../../utils/sysJwt";

const Aside = ({ username, isLoading, handleLogout }) => {
  const [toggled, setToggled] = useState(false);

  const handleClick = (event) => {
    setToggled(!toggled);
  };

  return (
    <div className={`app ${1 === 1 ? "rtl" : ""} ${1 === 1 ? "toggled" : ""}`}>
      <ProSidebar
        image={sidebarBg}
        rtl={true}
        collapsed={toggled}
        toggled={true}
        breakPoint="md"
        width={230}
      >
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 13,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {!isLoading ? (
              <i className="fa fa-bars" onClick={handleClick} />
            ) : (
              ""
            )}
            {isLoading ? (
              <ThreeCircles color="white" height="25" width="25" />
            ) : (
              <a href="/dashboard/home">نظام التحصيل الالكتروني</a>
            )}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {sysJwt.isAuthorized(["CABD"]) && (
              <SubMenu icon={<FaWhmcs />} title="النظام">
                {sysJwt.isAuthorized(["CABD", "CABE"]) && (
                  <MenuItem icon={<FaUsers />}>
                    <Link to="/dashboard/usermanagement">المستخدمين</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CAC5"]) && (
                  <MenuItem icon={<FaUserShield />}>
                    <Link to="/dashboard/rolemanagement">الصلاحيات</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CAD5"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/systemparameters">أعدادات النظام</Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CAF2"]) && (
              <SubMenu icon={<FaBookReader />} title="التعاريف">
                {sysJwt.isAuthorized(["CAF2", "CAD5"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/sectors">القطاعات</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CAF7"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/zones">المناطق</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CAFB"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/categories">انواع الانشطة</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB18"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/vouchertypes">انواع السندات</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CAFF"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/collectors">الجباة</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB06"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/subscribers">المشتركين</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB0A"]) && ( 
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/subscriberrequests">طلبات المشتركين</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB1C"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/municipalityproperty">الاملاك</Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CB0D"]) && (
              <MenuItem icon={<FaChartArea />}>
                الفواتير <Link to="/dashboard/bills" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB0E"]) && (
              <MenuItem icon={<FaChartArea />}>
                التسديدات <Link to="/dashboard/payments" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB20"]) && (
              <MenuItem icon={<FaChartArea />}>
                العقود <Link to="/dashboard/contracts" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB0F"]) && (
              <MenuItem icon={<FaChartArea />}>
                سندات القبض <Link to="/dashboard/vouchers" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB11"]) && (
              <MenuItem icon={<FaChartArea />}>
                سند اخراج <Link to="/dashboard/outgoingvouchers" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB13"]) && (
              <MenuItem icon={<FaChartArea />}>
                الصندوق الرئيسي <Link to="/dashboard/mainvouchers" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB15"]) && (
              <MenuItem icon={<FaChartArea />}>
                سندات اخراج مصرف <Link to="/dashboard/mainvouchersbank" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB25"]) && (
              <MenuItem icon={<FaChartArea />}>
                متابعة التحصيل <Link to="/dashboard/followup" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB17"]) && (
              <MenuItem icon={<FaChartArea />}>
                موقف الجباية <Link to="/dashboard/statement" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB28"]) && (
              <MenuItem icon={<FaChartArea />}>
                مبالغ المشتركين <Link to="/dashboard/subscriberdueamounts" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB29"]) && (
              <MenuItem icon={<FaChartArea />}>
                مبالغ الجباة <Link to="/dashboard/collectordueamounts" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CAEC"]) && (
              <MenuItem icon={<FaChartArea />}>
                التقارير <Link to="/dashboard/reports" />
              </MenuItem>
            )}
            <MenuItem icon={<FaInfo />}>
              <Link to="/dashboard/applicationinfo">حول النظام</Link>
            </MenuItem>
            <MenuItem icon={<FaLock />}>
              <Link to="/dashboard/changepassword">تغيير كلمة المرور</Link>
            </MenuItem>
            <MenuItem icon={<FaPowerOff />}>
              <a onClick={() => handleLogout()}>تسجيل خروج</a>
            </MenuItem>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <a className="sidebar-btn" rel="noopener noreferrer">
              <FaUserCheck />
              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {username}
              </span>
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default Aside;
