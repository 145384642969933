/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toastError } from "../../../utils/sysToast";
import { confirmAlert } from "react-confirm-alert";
import { replaceAll } from "../../../utils/stringHelper";
import { isNullOrUndefined } from "util";
import GridTable from "@nadavshaar/react-grid-table";

class Reports extends Component {
  state = {
    data: {
      ReportName: "",
      ReportDesc: "",
      ReportCategory: "",
      ReportUsers: [],
    },

    contentHeader: "التقارير",
    addButtonLabel: "اضافة تقرير",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getreports",
    singleItemUrl: "/api/data/getreport",
    addUrl: "/api/data/addreport",
    editUrl: "/api/data/editreport",
    deleteUrl: "/api/data/deletereport",

    inEditMode: false,
    inModalMode: false,

    modalHeaderAdd: "اضافة تقرير",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل تقرير",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    reportUsers: [],
    allCategories: [],
    allUsers: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "اسم التقرير",
        field: "REPORT_NAME",
      },
      {
        id: 2,
        label: "نوع التقرير",
        field: "REPORT_CAT",
      },
      {
        id: 3,
        label: "وصف التقرير",
        field: "REPORT_DESC",
      },
      {
        id: 4,
        label: "الاوامر",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CAF0"]) && (
                <div className="inner" title="تنفيذ التقرير">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.handleExecute(data);
                    }}
                  >
                    <i className="fa fa-bolt" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAEE"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });

    this.getReportCategories();
    this.getAllUsers();
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      reportUsers: [],
      allCategories: [],
      allUsers: [],
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          tableData: apiResponse.data.respData.data,
        });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { ReportId: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      for (var i = 0; i < apiResponse.data.respData.data.length; i++) {
        if (!isNullOrUndefined(apiResponse.data.respData.data[i].USER_ID)) {
          data["ReportUsers"].push(apiResponse.data.respData.data[i].USER_ID);
        }
      }

      data["ReportName"] = apiResponse.data.respData.data[0].REPORT_NAME;
      data["ReportDesc"] = apiResponse.data.respData.data[0].REPORT_DESC;
      data["ReportCategory"] = apiResponse.data.respData.data[0].REPORT_CAT;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  getReportCategories() {
    post(
      "/api/data/getreportcategoriesdropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allCategories: apiResponse.data.respData.data,
      });
    });
  }

  getAllUsers() {
    post(
      "/api/data/getallusersdropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allUsers: apiResponse.data.respData.data,
      });
    });
  }

  handleExecute(row) {
    post(
      "/api/data/getreportquery",
      { CodeId: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var query = apiResponse.data.respData.data;
      var queryParams = "";
      var isParameterized = false;

      if (query === null || query === undefined) {
        toastError("ليتم تنفيذه SQL التقرير لا يتحوي على");
        return;
      }

      // Replace bad symbols with spaces to preapre for splitting
      replaceAll(
        replaceAll(
          replaceAll(
            replaceAll(
              replaceAll(
                replaceAll(
                  replaceAll(
                    query.replace(/'/g, " ").replace(/(\r\n\t|\n|\r\t)/gm, " "),
                    "%",
                    " "
                  ),
                  "=",
                  " "
                ),
                "(",
                " "
              ),
              ")",
              " "
            ),
            ",",
            " "
          ),
          ">",
          " "
        ),
        "<",
        " "
      )
        .split(" ")
        .forEach((queryWord) => {
          if (
            queryWord.includes("&") &&
            !queryParams.split("&").includes(queryWord.replace("&", ""))
          ) {
            queryParams += queryWord;
            isParameterized = true;
          }
        });

      if (isParameterized) {
        window.location.replace(
          "/dashboard/executeReport?id=" +
            row.ID +
            "&rn=" +
            encodeURIComponent(row.REPORT_NAME) +
            "&ip=" +
            isParameterized +
            "&qp=" +
            encodeURIComponent(queryParams)
        );
      } else {
        window.location.replace(
          "/dashboard/executeReport?id=" +
            row.ID +
            "&rn=" +
            encodeURIComponent(row.REPORT_NAME)
        );
      }
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف التقرير",
      message: "هل انت متأكد من حذف هذا التقرير؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              {
                ReportId: row.ID,
              },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
      }
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {/* {sysJwt.isAuthorized(["CAED"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded flex-item-left mr-2"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({
                    inEditMode: false
                  });
                  this.showForm();
                }}
              />
            )} */}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "ReportName",
                label: "اسم التقرير",
                disabled: false,
              },
              {
                type: "text",
                id: "ReportDesc",
                label: "وصف التقرير",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "ReportCategory",
                label: "نوع التقرير",
                options: this.state.allCategories,
                disabled: false,
              },
              {
                type: "multiselect",
                id: "ReportUsers",
                label: "المستخدمين",
                options: this.state.allUsers,
                disabled: false,
                tooltip: "المستخدمين الذين لديهم الصلاحية لتنفيذ هذا التقرير",
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default Reports;
