/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";

class Collectors extends Component {
  state = {
    data: {
      firstName: "",
      secondName: "",
      lastName: "",
      phoneNo: "",
      username: "",
      sectorId: "",
      collectorZones: []
    },

    contentHeader: "الجباة",
    addButtonLabel: "اضافة جابي",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetCollectors",
    singleItemUrl: "/api/data/GetCollector",
    addUrl: "/api/data/AddCollector",
    editUrl: "/api/data/EditCollector",
    deleteUrl: "/api/data/DeleteCollector",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة جابي",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل جابي",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    sectorsDropdown: [],
    zonesDropdown: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "اسم الجابي",
        field: "FULL_NAME",
      },
      {
        id: 3,
        label: "القطاع",
        field: "AR_SECTOR_NAME",
      },
      {
        id: 4,
        label: "رقم الهاتف",
        field: "PHONE_NO",
      },
      {
        id: 5,
        label: "اسم المستخدم",
        field: "USERNAME",
      },
      {
        id: 6,
        label: "محظور",
        field: "IS_BLOCKED",
        width: "140px",
        cellRenderer: ({ data }) => {
          return data.IS_BLOCKED === "Y" ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;نعم&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#343A40",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;لا&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 7,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 8,
        label: "تاريخ التعديل",
        field: "LAST_UPDATE",
      },
      {
        id: 9,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB01"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB02"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB03"]) && (
                <div className="inner" title="حظر">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleBlock(data)}
                  >
                    <i className="fa fa-ban" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB04"]) && (
                <div className="inner" title="رفع الحظر">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleUnblock(data)}
                  >
                    <i className="fa fa-check" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB05"]) && (
                <div className="inner" title="اعادة كلمة السر الافتراضية">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.ResetCollectorPassword(data)}
                  >
                    <i className="fa fa-key" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getSectorsDropdown();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = null);
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["firstName"] = apiResponse.data.respData.data[0].FIRST_NAME;
      data["secondName"] = apiResponse.data.respData.data[0].SECOND_NAME;
      data["lastName"] = apiResponse.data.respData.data[0].LAST_NAME;
      data["phoneNo"] = apiResponse.data.respData.data[0].PHONE_NO;
      data["username"] = apiResponse.data.respData.data[0].USERNAME;
      data["sectorId"] = apiResponse.data.respData.data[0].SECTOR_ID_FK;

      for (var i = 0; i < apiResponse.data.respData.data.length; i++) {
        data["collectorZones"].push(apiResponse.data.respData.data[i].ZONE_ID_FK);
      }

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  getSectorsDropdown() {
    post("/api/data/getSectorsDropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          sectorsDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  getZonesDropdown = (input) => {
    console.log(input);
    post("/api/data/getZonesDropdown", { sectorId: input}, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          zonesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "label",
            "value"
          ),
        });
      }
    );
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف الجابي",
      message: "هل انت متأكد من حذف هذه الجابي؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "لا",
          onClick: () => null,
        },
      ],
    });
  }

  handleBlock(row) {
    post(
      "/api/data/BlockCollector",
      { CollectorId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleUnblock(row) {
    post(
      "/api/data/UnblockCollector",
      { CollectorId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  ResetCollectorPassword(row) {
    post(
      "/api/data/ResetCollectorPassword",
      { id: row.ID },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  render() {
    return (
      <div className="sys-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB00"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          headerStyle={{ "background-color": "#F0F0F0", color: "black" }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "firstName",
                label: "الاسم الاول",
                disabled: false,
              },
              {
                type: "text",
                id: "secondName",
                label: "الاسم الثاني",
                disabled: false,
              },
              {
                type: "text",
                id: "lastName",
                label: "الاسم الاخير",
                disabled: false,
              },
              {
                type: "text",
                id: "phoneNo",
                label: "رقم الهاتف",
                disabled: false,
              },
              {
                type: "text",
                id: "username",
                label: "اسم المستخدم",
                disabled: this.state.inEditMode,
              },
              {
                type: "dropdown",
                id: "sectorId",
                label: "القطاع",
                options: this.state.sectorsDropdown,
                customOnChangeHandler: this.getZonesDropdown,
                disabled: false,
              },
              {
                type: "multiselect",
                id: "collectorZones",
                label: "المناطق",
                options: this.state.zonesDropdown,
                disabled: false,
              }
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          showColumnVisibilityManager={false}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default Collectors;
